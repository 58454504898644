import React from 'react';
import { withRouter } from "react-router-dom";

import OffersGrid from '../offers-grid';
import FindMyDealBlock from '../find-my-deal-block';

import ServerApi from '../../services/server-api-service';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import ProjectCardsGrid from '../project-cards-grid';
import InnerArticleGrid from '../inner-article-grid';
import ConfigurationService from '../../services/configuration-service';

import './neighborhood-page-style.css';
import HOne from "../h-one";
class NeighborhoodPage extends React.Component {
    serverApi = new ServerApi();
    configurationService= new ConfigurationService();
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
        }
    }

    getNeighborhoodPageInfo() {
        if (this.props.match.params.id !== undefined) {
            this.serverApi.getNeighborhoodPageInfo(this.props.match.params.id)
                .then((data) => {
                    if (this.state.debug) console.log(data);
                    let countIteration = parseInt(data.area_projects.length / 4);
                    
                    let projectsArr = [];
                    for (let i = 0; i < countIteration; i++) {
                        for (let j = 0; j < 4; j++) {
                            projectsArr.push(data.area_projects[4 * i + j]);
                        }
                    }
                    if(data.area_projects.length<4) projectsArr =data.area_projects;

                    let articleLangSort = [];
                    data.articles.forEach((item,i,arr)=>{
                        console.log(this.configurationService.getCurrentLanguagePrefix())
                        if(item.language===this.configurationService.getCurrentLanguagePrefix()) articleLangSort.push(item)
                    })
                    articleLangSort.sort((article1, article2) => { return new Date(article2.date) - new Date(article1.date) })
                    this.setState({
                        articles: articleLangSort
                    })
    
                    this.setState({
                        data: data,
                        projectsArr:projectsArr,
                        articles:articleLangSort
                    })
                })
                .catch((error) => {
                    console.log(`ERROR: fetch getNeighborhoodPageInfo ... -> Component: NeighborhoodPage  ERROR:${error}`);
                })
        }
    }

    componentWillMount() {
        if (this.props.saveState) this.props.saveState({ currentPage: 'NeighborhoodPage' })
        this.getNeighborhoodPageInfo();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getNeighborhoodPageInfo();
        }
    }

    componentWillUnmount() {

    }


    //INFO: save this component for rollback :)
    render() {


        let body = null;
        let { dictionary, hoveredMarkerId } = this.props;
        if (this.state.data !== undefined) {
            let {data,projectsArr,articles} = this.state;
            let featuredDealsRender = null;
            if (data.featured_deals && data.featured_deals.length > 0) {
                featuredDealsRender = (
                    <div id="NeighborhoodDealsBlock" className='NeighborhoodLine'>
                        <OffersGrid
                            title={`${dictionary.smart_deals_in} ${data.area.name}`}
                            data={data.featured_deals}
                            alignTitle="center"
                            width={this.props.width}
                            dictionary={dictionary}
                            disableLoupe={true}
                            areaName={data.area.name}
                            neighborhoodImg={data.area.image_cover}
                        />
                    </div>
                )
            }

            let projectsRender = null;
            if (data.area_projects && data.area_projects.length > 0) {

                projectsRender = (
                    <div className="FeatureProducts NeighborhoodLine">
                        <Link to={'/projects'}>
                            <HOne
                                mainClass="NeighborhoodLineTitle"
                                align={'center'}
                                color={'black'}
                                title={dictionary.featured_projects}
                            />
                        </Link>
                        <ProjectCardsGrid
                            cards={projectsArr}
                            limit={100}
                            dictionary={dictionary}
                            hoveredMarkerId={hoveredMarkerId}
                            lastCard={false}
                        />
                    </div>

                )
            }

            let articlesRender = null;
            if (articles && articles.length > 0) {
                articlesRender = (
                    <div className="MoreArticles NeighborhoodLine">
                        <Link to={'/articles'}>
                            <HOne
                                mainClass="NeighborhoodLineTitle"
                                align={'center'}
                                title={dictionary.recent_articles}
                                color={'black'}
                            />
                        </Link>
                        <InnerArticleGrid
                            dictionary={dictionary}
                            data={articles}
                            limit={3}
                        />
                    </div>

                )
            }
            body = (
                <>
                    {
                        <Helmet>
                            <title> {data.area.name} | Smart Luxe</title>
                            <meta name="description" content={data.area.description} />
                        </Helmet>
                    }
                    <div className={"Neighborhood MenuSpace"}>
                        <div className="PageLogo">
                            <img id="PageLogoImg" alt="Logo" src={`https://ngirealty.com/img/smart_luxe/areas/cover/${data.area.image_cover}`}></img>
                            <div id="PageLogoTitle">
                                <HOne line={"lagre"} addclass={"lagresize"} title={data.area.name} />
                            </div>
                        </div>
                        <div className="NeighborhoodPageBody">
                            <div className="Block NeighborhoodTopBlock">
                                <div className="LeftBlock">
                                    <HOne title={dictionary.welcome_to + ' ' + data.area.name} align="left" />

                                    <div className={"NeighborhoodDescription"}>
                                        {data.area.description}
                                    </div>
                                </div>
                                <div id="NeighborhoodMap">
                                    <img src={`https://ngirealty.com/img/smart_luxe/areas/map/${data.area.map}`} alt="map" />
                                </div>
                            </div>
                            {projectsRender}
                            {featuredDealsRender}
                            {articlesRender}
                        </div>
                    </div>
                    <FindMyDealBlock
                        dictionary={dictionary}
                        title={data.area.name}
                        link={
                            {
                                pathname: `/neighborhood/${data.area.id}/${data.area.name.toLowerCase()}/find-my-deal`,
                                state: { area: data.area.name, neighborhoodImg: data.area.image_cover }
                            }
                        }
                    />
                </>
            )

        }
        else {
            body = null;
        }

        return (
            <div className="NeighborhoodPage">
                {body}
            </div>
        )
    }
}

export default withRouter(NeighborhoodPage);