import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import StringProcessingService from '../../services/string-processing-service';
import ConfigurationService from '../../services/configuration-service';
import parser from 'html-react-parser';

import './inner-article-style.css'

export default function InnerArticle(props) {
    const iconDate = require('../../elements/images/icon_date.svg').default;
    const iconDateWhite = require('../../elements/images/icon_date_white.svg').default;
    const iconLocation = require('../../elements/icons/s_location_offer.png').default;
    const tempMissoni = require('../../elements/images/missoni.png').default;
    const stringProcessingService = new StringProcessingService();
    const configurationService = new ConfigurationService(); 
    const [isVisibleLogo,setIsVisibleLogo] = useState(false);

    let { type = 'article', dictionary, data,projectId,projectName} = props;
    
    const renderStartPrice = () =>{
        let startPrice = 0; 
        if(data.price_1br!=null){
            startPrice = data.price_1br.toString();
        }else if(data.price_2br!=null){
            startPrice = data.price_2br.toString();
        } else if(data.price_3br!=null){
            startPrice = data.price_3br.toString();
        }else{
            return null;
        }
        return (
            <div className={"ProjectFeature"}>
            <div className={"Featuretitle"}>{dictionary.starting_price}:</div>
            <div className={"FeatureDescription"}>${stringProcessingService.convertToFormat(startPrice)}</div>
            </div>
        )
    }

    if (type === 'card') {
        let options = { year: 'numeric', month: 'long', day: 'numeric' };
        let date = new Date(data.completion).toLocaleDateString("en-US", options);
        let month, QValue = '';

     
        if (data.completion && data.completion !== null) {
            month = parseInt(data.completion.slice(5, 7));
            if ((month >= 1 && month <= 2)||month==12) QValue = dictionary.winter
                else if (month >= 3 && month <= 5) QValue = dictionary.spring
                else if (month >= 6 && month <= 8) QValue = dictionary.summer
                else if (month >= 9 && month <= 11) QValue = dictionary.fall
        }
        return (
            <Link onMouseEnter={()=>{setIsVisibleLogo(true)}} onMouseLeave={()=>{setIsVisibleLogo(false)}} to={`/projects/${data.id}/${stringProcessingService.StringFormatUrl(data.name)}`} className={"OfferParent"}>
                <div className={"OfferMain"}>
                    <div className={"TopBlockOffer"}>
                        {isVisibleLogo&&<div className={"ProjectLogo"}><img src={`https://ngirealty.com/img/projects/${data.id}/${data.logo}`} alt="" className={"ProjectLogoImg"} /></div>} 
                        <div className="OfferTitle">
                            <div className="OfferLocation"><img src={iconLocation} width="14" height="14" alt="" />{data.web_location}</div>
                        </div>
                        <img className="OfferImg" src={`https://ngirealty.com/storage/projects/${data.picture}`} alt="" />
                    </div>
                    <div className={"BottomBlockOfferProject"}>
                        <div className={"ProjectName"}>{data.name}</div>
                        <div className={"Separator"}></div>
                        <div className={"ProjectFeature ProjectFeatureOne"}>
                            <div className={"Featuretitle"}>{data.timeline===4?(dictionary.completed):(dictionary.estimated_delivery)}
                            :</div>
                            <div className={"FeatureDescription"}>{QValue} {data.completion && parseInt(data.completion.slice(0, 4))+((QValue===dictionary.winter&&month===12)&&1)}</div>
                        </div>
                        {renderStartPrice()}
                        <div className={"ReadMore"}><button name="TopForm" className="Button" id="ButtonSubmit">{dictionary.read_more}</button></div>
                    </div>
                </div>
            </Link>
        )
    } else {
        let options = { year: 'numeric', month: 'long', day: 'numeric' };

        let locale = 'en-US';
        switch(configurationService.getCurrentLanguagePrefix()){
            case 'pt':{
                locale="pt-PT";
                break;
            }
            case 'es':{
                locale="es-ES";
                break;
            }
            default:{
                break;
            }
    
        }
        let date = new Date(data.date).toLocaleDateString(locale, options);
        return (
            <Link onMouseEnter={()=>{setIsVisibleLogo(true)}} onMouseLeave={()=>{setIsVisibleLogo(false)}} to={projectId!=null && projectName != null ?
            `/projects/${projectId}/${stringProcessingService.StringFormatUrl(projectName)}/articles/${data.id}/${stringProcessingService.StringFormatUrl(data.title)}`:
            `/articles/${data.id}/${stringProcessingService.StringFormatUrl(data.title)}`
             } className={"OfferParent"} id='InnerArticleCard'>
                <div className={"OfferMain"}>
                    <div className={"TopBlockOffer"}>
                    {isVisibleLogo&&data.project!=null ?<div className={"ProjectLogo"}><img src={`https://ngirealty.com${data.project.logo}`} alt="" className={"ProjectLogoImg"} /></div>:null} 
                        <div className="OfferTitle">
                            <div className="OfferLocation"><img src={iconDateWhite} width="14" height="14" alt="" />{date}</div>
                        </div>
                        <div className='OfferImgBlock'>
                             <img className="OfferImg" src={data.photo} alt="" />
                        </div>
                    </div>
                    <div className={"BottomBlockOfferProject"}>
                        <div className={"ArtName"}>{data.title}</div>
                        <div className={"ArtP"}>
                            {parser(data.text_preview)}
                        </div>
                        <div className={"ReadMore"}><button name="TopForm" className="Button" id="ButtonSubmit">{dictionary.read_more}</button></div>
                    </div>
                </div>
            </Link>

        )
    }

}