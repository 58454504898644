import React from 'react';

import ServerApi from '../../services/server-api-service';
import HOne from '../h-one';
import Parser from 'html-react-parser';

import './privacy-page-style.css';
class PrivacyPage extends React.Component {
  serverApi = new ServerApi();
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
    }
  }

  componentDidMount() {
    if(this.props.saveState) this.props.saveState({currentPage:'PrivacyPage'})
    this.serverApi.getPrivacyInfo()
      .then((data) => {
        if (this.state.debug) {
          console.log(`getPrivacyInfo data:`, data);
        }

        this.setState({
          data: data
        })
      })
      .catch((error) => {
        console.log(`ERROR: fetch getPrivacyInfo ... -> Component: PrivacyPage ERROR:${error}`);
      })
  }

  renderBody(){
    let data = this.state.data;
    if(data!==undefined){
      let parsedIntro = data!==undefined?Parser(data.intro):'';
      return(
        <div className="Block" id="PrivacyBlock">
          <HOne  title={data.title}/>
          <div className="PrivacyText">
            {parsedIntro}
            {
              data.section.map((item,index)=>{
                return(
                <div key ={index} className="PrivacyItem">
                  <div>
                    {item.title}
                  </div>
                  {item.desc}
                </div>
                )
              })
            }
          </div>
        </div>
      )
    }
  }

  render() {
    

    let pageLogo = require('../../elements/images/privacy_page.png').default;
    return ( 
      <div className="PrivacyPage Page">
        <div className="PageLogo">
          <img id="PageLogoImg" src={pageLogo} alt='Logo'></img>
          <div id="PageLogoTitle"><HOne title={this.state.data!==undefined?this.state.data.title:''} line={"large"} addclass={"lagresize upper"} /></div>
        </div>
        {this.renderBody()}
      </div>
    )
  };

}

export default PrivacyPage;