import React from 'react';
import Parser from 'html-react-parser';
import ConfigurationService from '../../services/configuration-service';
import './deal-score-style.css';

import HOne from '../h-one';

function imageExists(image_url){

    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status != 404;

}

export default function DealScore(props){
        const configurationService = new ConfigurationService();
        let { dictionary,analyseInfo,handleDealScore,for_top = false,width,visible=false} = props;
        let dealScoreRender = null;
        let rank = 0;
        if (analyseInfo !== undefined) {
            rank = analyseInfo.rank;
        }

        const currentLanguage =configurationService.getCurrentLanguagePrefix();
        
        const imgExcellent = require(`../../elements/images/excellent_deal_${currentLanguage}.png`).default;
        const imgGreat = require(`../../elements/images/great_deal_${currentLanguage}.png`).default;
        const imgGood = require(`../../elements/images/good_deal_${currentLanguage}.png`).default;




        if (rank === 2) {
            dealScoreRender = (
                <div className={"DealScoreLine"} onClick={handleDealScore}>
                    <img className="DealScoreImg" src={imgGood} alt=""></img>
                    <div className={"PoweredBy"}>
                        {Parser(dictionary.slogan_powered_by)}
                    </div>
                </div>
            );
            if (for_top) {
                dealScoreRender = (
                        <img className="DealScoreImg" src={imgGood} alt=""></img>
                );
            }
        }
        else if (rank === 3) {
            dealScoreRender = (
                <div className={"DealScoreLine"} onClick={handleDealScore}>
                    <img className="DealScoreImg" src={imgGreat} alt=""></img>
                    <div className={"PoweredBy"}>
                        {Parser(dictionary.slogan_powered_by)}
                    </div>
                </div>
            );
            if (for_top) {
                dealScoreRender = (
                    <img className="DealScoreImg" src={imgGreat} alt=""></img>
                );
            }
        }
        else if (rank === 4) {
            dealScoreRender = (
                <div className={"DealScoreLine"} onClick={handleDealScore}>
                    <img className="DealScoreImg" src={imgExcellent} alt=""></img>
                    <div className={"PoweredBy"}>
                        {Parser(dictionary.slogan_powered_by)}
                    </div>
                </div>
            );
            if (for_top) {
                dealScoreRender = (
                    <img className="DealScoreImg" src={imgExcellent} alt=""></img>
                );
            }
        } else {
            dealScoreRender = (
                null
            )
        }

        if (for_top && width > 1000&&!visible) {
            dealScoreRender = (
                null
            )
        }

        if (!for_top && width < 1000&&!visible) {
            dealScoreRender = (
                null
            )
        }

        if (rank!==undefined&&rank > 1) {
            let dealScoreHeader = (<HOne title={dictionary.deal_score} addclass={"xsmall"} />);

            if (!for_top && width < 1000 &&!visible) {
                dealScoreHeader = (
                    null
                )
            }

            if (for_top) {
                dealScoreHeader = ''
            }
            return (
                <>
                    {dealScoreHeader}
                    {dealScoreRender}
                </>
            );
        } else {
            return (
                null
            );
        }
}