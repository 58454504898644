import React, { useState } from 'react';

import './custom-list-style.css'
import ReadMore from '../read-more'
import Slider from 'react-slick';
import { AnimatePresence, motion } from 'framer-motion';
import StringProcessingService from '../../services/string-processing-service';

let limit  = 9

function CustomList(props) {
    const [isVisible, setVisible] = useState(false);
    const pageTransition = {
        in: {
            opacity: 1
        },
        out: {
            opacity: 0
        }
    }

    let { elem, style, listName,dictionary,width} = props;
    if(width<=800) limit = 4;
    else limit =9;
    
    if (elem) {
        let arr = StringProcessingService.parseNodeElem(elem);
        if (arr === false) {
            return (<div>
                FIX {listName} - MUST BE BULLETS
                <span className='error'>
                    Fix {listName} list
                </span>
            </div>)
        } else if (Array.isArray(arr)) {
            let firstPart = []
            let secondPart = [];
            for (let i = 0; i < arr.length; i++) {
                if (arr.length > limit) {
                    if (i < limit) {
                        firstPart.push(arr[i]);
                    } else {
                        secondPart.push(arr[i]);
                    }
                }
                else firstPart.push(arr[i]);

            }

            return (
                <>
                    <ul style={style} className={"CustomList"}>
                        {
                            firstPart.map((item, index) => {
                                return (
                                    <li className={"Feature"} key={`FeaturesFirstPart${index}`}>
                                        <div className={"Pointer"}></div>
                                        <div className={"FeatureText"}>{item}</div>
                                    </li>
                                )
                            })
                        }

                        {isVisible && (
                            <motion.div
                                variants={pageTransition}
                                exit="out"
                                animate="in"
                                initial="out">
                                {
                                    secondPart.map((item, index) => {
                                        return (
                                            <li className={"Feature"} key={`FeaturesSecondPart${index}`}>
                                                <div className={"Pointer"}></div>
                                                <div className={"FeatureText"}>{item}</div>
                                            </li>
                                        )
                                    })
                                }
                            </motion.div>
                        )}

                    </ul>
                    {arr.length > limit ? (
                        <ReadMore
                            style={{ paddingLeft: '20px' }}
                            custom={true}
                            visible={isVisible}
                            changeVisible={setVisible}
                            dictionary={dictionary}
                        ></ReadMore>
                    ) : (null)}
                </>
            )
        }
    }
    return null;
}

export default CustomList;