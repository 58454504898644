import React from 'react';
import GoogleMap from '../google-map';
// import { browserName } from "react-device-detect";
import './selection-map-style.css';
import StringProcessingService from '../../services/string-processing-service'
import MarkerCustomInfoWindow from '../marker-custom-info-window/marker-custom-info-window';
const stringProcessingService = new StringProcessingService();

function SelectionMap(props) {
    let {dictionary,currentSelectionListing,selectionMapFloating,width,height,
        listingExpiredList,hoveredMarkerId,listings,agentId,
        selectionId,leftBlockWidth=800,mapSecondaryMarkers,mapSoldOutMarkers,currentPage} = props;
    let coord;
    if(currentSelectionListing!==null&&currentSelectionListing.Latitude!==undefined&&currentSelectionListing.Longitude!==undefined){
         coord={
            lat:currentSelectionListing.Latitude,
            lng:currentSelectionListing.Longitude
        }
    }
    let mapTopPadding = 0;
  //,  paddingTop:window.scrollY-166
    let SelectionMapStyle = {
        
    }

    function handleOnMarkerHover (item) {
        return(
            <MarkerCustomInfoWindow
                dictionary={dictionary}
                item={item}
                type={'Projects'}
            />
        )
    }

    //if(document.location.pathname==='/projects'){
    if(selectionMapFloating){
        let pos = 'relative';
        let yOffset = 0;
        let alignSelf='';

        let footerHeignt = document.getElementsByClassName('Footer')[0].scrollHeight

        var scrollHeight = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
          );
          
        if(window.pageYOffset>170){
            pos='fixed'
            yOffset=40;
        }

        if(window.pageYOffset>scrollHeight-footerHeignt-window.innerHeight){
            pos = 'relative';
            alignSelf ='flex-end'
            yOffset=0
        }

        // console.log('scrollHeight',scrollHeight);
        // console.log('window.pageYOffset',window.pageYOffset);
        // console.log('footerHeignt',footerHeignt);
        SelectionMapStyle= {height:height-40,width:width-leftBlockWidth,position:pos,right:0,top:yOffset,alignSelf:alignSelf};
       
    } 
    if(width>1000&& listings!==undefined){
        return (
            <div className='SelectionMap' style={SelectionMapStyle}>
                <GoogleMap
                    // width={`${window.screen.width/2.5}px`}
                    // height={`${window.screen.height/1.4}px`}
                    mapSoldOutMarkers={mapSoldOutMarkers}
                    mapSecondaryMarkers={mapSecondaryMarkers}
                    width='100%'
                    height='100%'
                    coord={coord}
                    screenWidth ={width}
                    hoveredMarkerId={hoveredMarkerId}
                    handleOnMarkerHover={handleOnMarkerHover}
                    dataCenter={currentSelectionListing===null?true:false}
                    dataMarkers={true}
                    listingExpiredList={listingExpiredList}
                    data={listings}
                    agentId={agentId}
                    selectionId={selectionId}
                    currentSelectionListing={currentSelectionListing}
                    currentPage={currentPage}
                />
            </div>
        )
    }else return null;
}
export default SelectionMap;