import React from 'react';
import GoogleMap from '../google-map';
import './contact-us-page-style.css';
import HOne from "../h-one";

import ServerApi from '../../services/server-api-service';
import FormCheckService from '../../services/form-check-service';
import CookiesManager from '../../services/cookies-manager';
import UserForm from '../user-form';
import Helmet from 'react-helmet';
class ContactUsPage extends React.Component {
    formCheckService = new FormCheckService();
    serverApi = new ServerApi();
    cookiesManager = null;
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            contactInfo: {
                userComment: "",
                userEmail: "",
                userFullName: "",
                userPhone: ""
            },
            dataForMap:[{
                latitude:26.013954027878068,
                longitude:-80.17603655784397,
            }],
            contactIsSended: false
        }
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    

    componentWillMount(){
        if(this.props.saveState) this.props.saveState({currentPage:'ContactUsPage'})
    }
    componentDidMount(){
        this.cookiesManager = new CookiesManager();
    }

    handleInput(event) {
        this.setState({
            contactInfo: {
                ...this.state.contactInfo,
                [event.target.name]: event.target.value
            }
        })
    }

    clearForm = ()=>{
        this.setState({
            ...this.state,
            contactInfo:{
                userComment: "",
                userEmail: "",
                userFullName: "",
                userPhone: ""
            }
        })
    }

    handleSubmit() {
        if (!this.formCheckService.checkContactForm(this.state.contactInfo)) {
            this.serverApi.sendForm(this.state.contactInfo)
                .then((data) => {
                    this.cookiesManager.set('exit_pop','1',365)
                    if (this.state.debug) {
                        console.log(data);
            
                    }
                    this.setState({
                        contactIsSended: true
                    })
                })
                .catch((error) => {
                    console.log(`ERROR: fetch sendForm ... -> Component: Contact Us page ERROR:${error}`);
                })
        }
    }

    renderAnswer() {
        const okeyIcon = require('../../elements/icons/okey.png').default;

        let { dictionary } = this.props;

        if (this.state.contactIsSended) {
            return (
                <div className="ThankYouBlock">

                    <img src={okeyIcon} alt='okey' />
                    {dictionary.thank_you_short}
                </div>
            )
        }
    }

    render() {

        const pageLogo = require('../../elements/images/contact_us.png').default;
        const iconLetter = require('../../elements/icons/s_letter.png').default;
        const iconLocation = require('../../elements/icons/s_location.png').default;
        const iconLock = require('../../elements/icons/s_secured.png').default;
        let { dictionary,width,country} = this.props;
        let {contactInfo,dataForMap} = this.state;
        return (
            <div className={"ContactUsPage MenuSpace"}>

                <Helmet>
                    <title> {dictionary.contact_us} | Smart Luxe</title>
                    <meta name="description" content={dictionary.message_us} />
                </Helmet>

                <div className="PageLogo">
                    <img id="PageLogoImg" src={pageLogo} alt='Page logo'></img>
                    <div id="PageLogoTitle"><HOne title={dictionary.contact_us} line={"large"} addclass={"lagresize"} /></div>
                </div>
                <div className="ContactUsForm">

                    <div className="LeftBlock">
                        <div className="FormDiv">
                            <HOne title={dictionary.message_us} align={'left'} />

                            <UserForm
                                clearForm={this.clearForm}
                                topPartRow={true}
                                contactForm={contactInfo}
                                handleInput={this.handleInput}
                                width={width}
                                country={country}
                                thankModal={true}
                                secureInfo={true}
                                dictionary={dictionary}
                                className={'Form'}
                                CustomInputStyle={{ padding:'0 0 0 5px'}}
                                style={{ textAlign: 'center', display:'block', justifyContent: 'center'}} />

                            {/* <button className="Button" id="ButtonPrev" onClick={this.handlePrev}>BACK</button>*/}
                        </div>
                    </div>

                    <div className="RightBlock">
                        <div className={"ContactInfo"}>
                            <HOne title={dictionary.get_in_touch} align={'left'} />
                            <div className="TouchBlock">
                                <span>{dictionary.main_office}</span>
                                <div className="TouchBlockInfo">
                                    <div className="TouchBlockLeft">
                                        <div className="TBItem">
                                            English:<a href='tel:+13057604884'> +1 (305) 760 4884</a>
                                        </div>
                                        <div className="TBItem">
                                            Español:<a href='tel:+13057706124'> +1 (305) 770 6124</a><br />
                                        </div>
                                        <div className="TBItem">
                                            Português:<a href='tel:+13052858518'> +1 (305) 285 8518</a>
                                        </div>
                                    </div>
                                    <div className="TouchBlockRight">
                                        <div className="TBItem">
                                            <div className={"TBItemImage"}><img src={iconLetter}  alt='Icon letter'/></div>
                                            <div className={"TBItemText"}><a href="mailto:info@smartluxe.com"> info@smartluxe.com</a></div>
                                        </div>
                                        <div className="TBItem">
                                            <div className={"TBItemImage"}><img src={iconLocation} alt='Icon location'/></div>
                                            <div className={"TBItemText"}>450 N. Park Rd, Unit 503, <br /> Hollywood, FL 33021</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div>
                    <GoogleMap
                        // markCoords={{
                        //     lat: 26.013954027878068,
                        //     lng: -80.17603655784397
                        // }}
                        data={dataForMap }
                        // coord={{
                        //     lat: 26.013954027878068,
                        //     lng: -80.17603655784397
                        // }}
                    //    lat ={this.state.Latitude}
                    //    lng = {this.state.Longitude}
                    />
                </div>
            </div>
        )
    }

}

export default ContactUsPage;