function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    const nextIcon = require('../../elements/icons/next_icon.png').default;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src={nextIcon} alt="Next" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    const prevIcon = require('../../elements/icons/prev_icon.png').default;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src={prevIcon} alt="Prev" />
        </div>
    );
}


export {SampleNextArrow,SamplePrevArrow};