import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import StringProcessingService from '../../services/string-processing-service';
import CookiesManager from '../../services/cookies-manager';
import ServerApi from '../../services/server-api-service';
import FormCheckService from '../../services/form-check-service';
import ConfigurationService from '../../services/configuration-service';
import Parser from "html-react-parser";
import HOne from "../h-one";
import ThanksModal from '../thanks-modal';
import UserForm from '../user-form';
import {SampleNextArrow,SamplePrevArrow} from '../../functions/SliderArrow/SliderArrow.js';

import Slider from 'react-slick';
// import {
//     Link
// } from "react-router-dom";

import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';


import './listing-page-modal-style.css';
class ListingPageModal extends React.Component {
    stringProcessingService = new StringProcessingService();
    serverApi = new ServerApi()
    formCheckService = new FormCheckService();
    cookiesManager = null;
    configurationService = new ConfigurationService();
    constructor(props) {
        super(props);
        this.state = {
            currentImgId: '0',
            contactIsSended: false,
            contactInfo: {
                userComment: "",
                userEmail: "",
                userFullName: "",
                userPhone: ""
            },
            currentSlide: props.currentSlide,
            isVisibleThanksModal: false
        }
    }

    componentDidMount() {
        this.cookiesManager = new CookiesManager();
        if (this.props.data) {
            let {data,analyseInfo} = this.props;
            this.setState({
                currentImgId: data.Media[0].Order,
                currentImg: data.Media[0].MediaUrl
            })

            if(analyseInfo!=null){
                let property = analyseInfo.rank === 3 || analyseInfo.rank === 4 ? `${this.props.dictionary.deal}.` : `${this.props.dictionary.property}.`;
                this.setState({
                    ...this.state,
                    contactInfo: {
                        ...this.state.contactInfo,
                        userComment: `${this.props.dictionary.send_me_more_details} ${property}`
                    }
                })
            }else{
                this.setState({
                    ...this.state,
                    contactInfo: {
                        ...this.state.contactInfo,
                        userComment: `${this.props.dictionary.send_me_more_details} ${this.props.dictionary.deal}`
                    }
                })
            }
           
        }
    }

    componentWillUnmount(){
        document.body.style.overflow = "auto";
    }

    handleInput = (event) => {
        this.setState({
            contactInfo: {
                ...this.state.contactInfo,
                [event.target.name]: event.target.value
            }
        })
    }

    closeThanks = () => {
        this.setState({
            isVisibleThanksModal: false
        })
    }

    handleSwitchMedia = (newImage) => {
        this.setState({
            currentImgId: newImage.Order,
            currentImg: newImage.MediaUrl
        })
    }

    openThanksModal = () => {
        this.setState({
            contactIsSended: true,
            isVisibleThanksModal: true
        })
    }

    clearForm = () => {
        this.setState({
            ...this.state,
            contactInfo: {
                userComment: "",
                userEmail: "",
                userFullName: "",
                userPhone: ""
            },
        })
    }

    handleSubmit = (event) => {
        if (!this.formCheckService.checkContactForm(this.state.contactInfo)) {
            this.serverApi.sendForm(this.state.contactInfo)
                .then((data) => {
                    this.cookiesManager.set('exit_pop', '1', 365)
                    if (this.state.debug) {
                        console.log(data);
                    }
                    if (event.target.name === "BottomForm") {
                        this.setState({
                            contactIsSended: true
                        })
                    } else {
                        this.setState({
                            isVisibleThanksModal: true
                        })
                    }

                })
                .catch((error) => {
                    console.log(`ERROR: fetch sendForm ... -> Component: Wizard-Page ERROR:${error}`);
                    this.props.history.replace({pathname:`/page-not-found`});
                })
        }
    }

    renderMediaSwitch() {
        let { data } = this.props;
        let moreImg = require('../../elements/images/more_image.png').default;
        if (data) {

            return (
                <div className="MediaSwitchDiv">{data.Media.map((item, index) => {
                    if (this.state.currentImgId === item.Order && index < 4) {
                        return (
                            <div key={index}>
                                <div className="MediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, item)}>
                                    <img src={item.MediaUrl} className="MediaImgSmall" alt="" ></img>
                                </div>
                            </div>
                        )
                    } else if (index < 4) {
                        return (
                            <div key={index}>
                                <div className="MediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, item)}>
                                    <img src={item.MediaUrl} className="MediaImgSmall" alt="" ></img>
                                    <div className="Other"></div>
                                </div>
                            </div>
                        )
                    } else if (index === 5) {
                        return (
                            <div key={index}>
                                <div className="MediaSwitchItem">
                                    <img src={moreImg} className="MediaImgSmall" alt="" ></img>
                                </div>
                            </div>
                        )
                    }

                })}</div>
            )
        } else return null

    }


    renderAnswer() {
        let { dictionary } = this.props;
        const okeyIcon = require('../../elements//icons/okey.png').default
        if (this.state.contactIsSended) {
            return (
                <div>
                    <img src={okeyIcon} className="IconFormSmall" alt=''></img>
                    {dictionary.thank_you_short}
                </div>
            );
        }
    }

    renderCounter(){
        let {currentSlide} = this.state;
        let {data} =  this.props;
        return (
            <div className="Counter">
                {currentSlide+1} of {data.Media.length}
            </div>
        )
    }

    renderMedia() {
        let { data, height } = this.props;

        const speed= 500;
        const settingsSlider = {
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            beforeChange: (current, next) => {
                setTimeout(()=>{
                    this.setState({
                        currentSlide:next
                    })
                }, speed)
              },
              speed
        };

        let SliderItems = data.Media.map((item, index) => {
            if (this.state.currentSlide === index) {
                return (
                    <div key={index}>
                        <div className="TopSliderItem" >
                            <img style={{ maxHeight: height - 105 }} src={item.MediaUrl} className="TopSliderImg" alt="" ></img>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div key={index}>
                        <div className="TopSliderItem" >
                            <img style={{ maxHeight: height - 105 }} src={item.MediaUrl} className="TopSliderImg" alt="" ></img>
                            <div className="Other"></div>
                        </div>
                    </div>
                )
            }
        })
        if (false) {   //data.Media.length <= 4
            return (
                <div className={"PropImages"}>
                    <div className="MediaCurrentDiv">
                        <img className="MediaImgBig" src={this.state.currentImg} alt=""></img>
                    </div>
                    {this.renderMediaSwitch()}
                </div>
            )
        } else {
            return (
                // <div className="SliderTop"> Слайдер </div>
                <div className="SliderTop">
                    <Slider {...settingsSlider}
                        adaptiveHeight={true}
                        centerMode={false}
                        focusOnSelect={true}
                        initialSlide={this.props.currentSlide}
                        onInit={()=>{
                            this.setState({
                                currentSlide:this.props.currentSlide
                            })
                        }}
                        // afterChange={(index) => this.setState({ currentSlide: index })}
                    >
                        {SliderItems}
                    </Slider>
                    {this.renderCounter()}
                </div>
            )
        }

    }

    renderPoweredBy(render) {
        let { dictionary } = this.props;
        if (!render) {
            return (
                <div className={"PoweredBy"}>
                    {Parser(dictionary.slogan_powered_by)}
                </div>
            )
        }
    }

    renderDealScore(atHeader = false) {
        let { dictionary, dealScore } = this.props;
        if (dealScore) {
            let dealScoreRender = null;
            let rank = 0;
            if (this.props.analyseInfo !== undefined) {
                rank = this.props.analyseInfo.rank;
            }
            const currentLanguage = this.configurationService.getCurrentLanguagePrefix()
            const imgExcellent = require(`../../elements/images/excellent_deal_${currentLanguage}.png`).default;
            const imgGreat = require(`../../elements/images/great_deal_${currentLanguage}.png`).default;
            const imgGood = require(`../../elements/images/good_deal_${currentLanguage}.png`).default;



            if (rank === 2) {
                dealScoreRender = (
                    <div className={"DealScoreLine"} onClick={this.handleDealScore}>
                        <img className="DealScoreImg" src={imgGood} alt=""></img>
                        {this.renderPoweredBy(atHeader)}
                    </div>
                )
            }
            else if (rank === 3) {
                dealScoreRender = (
                    <div className={"DealScoreLine"} onClick={this.handleDealScore}>
                        <img className="DealScoreImg" src={imgGreat} alt=""></img>
                        {this.renderPoweredBy(atHeader)}
                    </div>
                )
            }
            else if (rank === 4) {
                dealScoreRender = (
                    <div className={"DealScoreLine"} onClick={this.handleDealScore}>
                        <img className="DealScoreImg" src={imgExcellent} alt=""></img>
                        {this.renderPoweredBy(atHeader)}
                    </div>
                )
            } else {
                dealScoreRender = (
                    null
                )
            }
            if (rank !== 0) {
                return (
                    <>
                        {!atHeader && <HOne title={dictionary.deal_score} addclass={"xsmall"} />}
                        {dealScoreRender}
                    </>
                );
            } else {
                return (
                    null
                );
            }
        } return null;

    }

    renderDealScoreContactForm() {
        let { dictionary, userForm = true, country, width } = this.props;
        let { contactInfo } = this.state;
        const iconLock = require('../../elements/icons/s_secured.png').default;
        if (userForm) {
            return (
                <div className={"DealScoreContact"}>
                    <HOne title={dictionary.request_contact} addclass={"xsmall"} />
                    <br></br>



                    {/* <div className="DealScoreContactDiv">
                        <div className="DealInputs">
                            <input type="text" name="userFullName" className="Input " placeholder={dictionary.full_name} value={this.state.contactInfo.userFullName} onChange={this.handleInput} />
                            <input type="text" name="userPhone" className="Input" placeholder={dictionary.phone} value={this.state.contactInfo.userPhone} onChange={this.handleInput} />
                            <input type="text" name="userEmail" className="Input" placeholder={dictionary.email} value={this.state.contactInfo.userEmail} onChange={this.handleInput} />
                            <textarea type="text" name="userMessage" className="Input DealMessage" placeholder={dictionary.message} value={this.state.contactInfo.userComment} onChange={this.handleInput} />

                        </div>
                    </div>
                    <div className="SecureDiv ListingPageSecureBlock">
                        <img className="SecureIcon" src={iconLock} alt="" />
                        <span className="Text">
                            {dictionary.information_secure}
                        </span>
                    </div>
                    <br />
                    <button name="TopForm" className="Button" id="ButtonSubmit" onClick={this.handleSubmit}>{dictionary.btn_request_details}</button> */}



                    <UserForm
                        clearForm={this.clearForm}
                        contactForm={contactInfo}
                        handleInput={this.handleInput}
                        width={width}
                        country={country}
                        secureInfo={true}
                        dictionary={dictionary}
                        openThanksModal={this.openThanksModal}
                        style={{ textAlign: 'center', margin: '40px', justifyContent: 'center' }}
                    />
                </div>
            )
        } else return null;
    }

  

    render() {
        let { isVisible, data, dictionary, width, height, selection = false } = this.props;
        let ParsedListingPrice = this.stringProcessingService.convertToFormat(data.ListPrice.toString());
        let ParsedSquareFeet = data.PropertyType!=="Land/Boat Docks"? this.stringProcessingService.convertToFormat(data.LivingArea.toString()):(
            this.stringProcessingService.convertToFormat(data.LotSizeSquareFeet.toString())
        )
        let flag = !isVisible || width < 1200;

        if (!flag) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return (
            <>
                <AnimatePresence>
                    {(flag) ? (null) : (
                        <div
                            className="ModalCarusel"

                        >
                            <motion.div
                                className="ListingPageModal"
                                transition={{ duration: 0.3 }}
                                initial={{ scale: 0, opacity: 1 }}
                                exit={{ scale: 0, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                            >
                                <div className="ListingPageModalBody">
                                    <div className={"ListinPageHeaderLine"}>
                                        <div className="ListinPageStaticHeader">
                                            <div className="Title">
                                                <div className={"VertLine"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64.484" viewBox="0 0 2 64.484">
                                                        <defs>
                                                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                                <stop offset="0" stopColor="#a96903" />
                                                                <stop offset="0.258" stopColor="#aa6a02" />
                                                                <stop offset="0.352" stopColor="#b07102" />
                                                                <stop offset="0.418" stopColor="#b97d02" />
                                                                <stop offset="0.472" stopColor="#c78e01" />
                                                                <stop offset="0.518" stopColor="#daa401" />
                                                                <stop offset="0.557" stopColor="#f0be00" />
                                                                <stop offset="0.579" stopColor="#ffd100" />
                                                                <stop offset="1" stopColor="#a96903" />
                                                            </linearGradient>
                                                        </defs>
                                                        <g id="Сгруппировать_94" data-name="Сгруппировать 94" transform="translate(-272.933 -2822.173) rotate(90)">
                                                            <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="64.484" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div className="TitieAndDisc">
                                                    <h2>
                                                        {/* 18555 Collins Avenue, Unit 4803 */}
                                                        {`${data.StreetNumber} ${data.StreetName} ${data.UnitNumber}`}
                                                    </h2>
                                                    {`${data.City}, ${data.StateOrProvince} ${data.PostalCode}`}
                                                </div>

                                            </div>
                                            <div className="MainInfo">
                                                <span className="Separator FirstSep"></span>
                                                <div className="Item">
                                                    {dictionary.bedrooms}:   <br /><span>   {data.BedroomsTotal !=null && data.BedroomsTotal !== '' ? data.BedroomsTotal:(`N/A`)}  </span>
                                                </div>
                                                <span className="Separator"></span>
                                                <div className="Item">
                                                    {dictionary.bathrooms}:  <br /> <span>  {data.BathroomsTotalDecimal !=null && data.BathroomsTotalDecimal !== '' ? data.BathroomsTotalDecimal:(`N/A`)}</span>
                                                </div>
                                                <span className="Separator"></span>
                                                <div className="Item">
                                                    {dictionary.area_metric_full}:  <br /> <span> {ParsedSquareFeet !=null && ParsedSquareFeet !== '' ? ParsedSquareFeet:(`N/A`)}</span>
                                                </div>
                                                <span className="Separator PrevSep"></span>
                                                <div className="Item Last PriceInRow Price">
                                                    <div>
                                                        {dictionary.listing_price}:  <br /> <span>$ {ParsedListingPrice !=null && ParsedListingPrice !== '' ? ParsedListingPrice:(`N/A`)}</span>
                                                    </div>
                                                </div>

                                                {(height <= 770) ? (
                                                    <>
                                                        <span className="Separator"></span>
                                                        <div className={"DealScoreAtTop"} >
                                                            {this.renderDealScore(true)}
                                                        </div>
                                                    </>
                                                ) : (null)}

                                                <span className="Separator LastSep"></span>
                                            </div>
                                            <div className="Item Last PriceInCol Price">
                                                <div>
                                                    {dictionary.listing_price}:  <br /> <span>${ParsedListingPrice}</span>
                                                </div>
                                            </div>
                                            <div className="Item" onClick={this.props.closeModal}>
                                                <div className={"Close"}>
                                                    X
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* media */}
                                    <div className="MediaMain" >
                                        <div className="MediaBlock">
                                            {this.renderMedia()}
                                        </div>
                                        {!selection ? (
                                            <div className="DealScoreAndContacts">
                                                {(height >= 770) ? (this.renderDealScore(false)) : (null)}
                                                {this.renderDealScoreContactForm()}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </motion.div>
                            <ThanksModal
                                isVisible={this.state.isVisibleThanksModal}
                                title={dictionary.thank_you}
                                message={dictionary.thank_you_desc}
                                closeModal={this.closeThanks}
                            />
                        </div>
                    )}
                </AnimatePresence>
            </>
        )
    };

}

export default ListingPageModal;