import React,{useState} from 'react'
import { AnimatePresence, motion } from 'framer-motion';

import './read-more-style.css'
const ReadMore = (props) => {
    const [isVisible, setVisible] = useState(false);

    const pageTransition = {
        in: {
            opacity: 1
        },
        out: {
            opacity: 0
        }
    }
    const arr_down = require('../../elements/images/arr_down.svg').default;
    const arr_up = require('../../elements/images/arr_up.svg').default;
    
    let {custom,visible,changeVisible,children, style,dictionary} = props;

    if(custom){
        return (
            <div style={style} className={"ReadMore"} onClick={() => { changeVisible(!visible) }}>{dictionary.read_more} <img src={visible ? arr_up : arr_down} alt="" /></div>
        )
    }else{
        return (
            <>
    
                {isVisible && (
                    <motion.div
                        variants={pageTransition}
                        exit="out"
                        animate="in"
                        initial="out">
                        {children}
                    </motion.div>
                )}
                <div style={style} className={"ReadMore"} onClick={() => { setVisible(!isVisible) }}>{dictionary.read_more} <img src={isVisible ? arr_up : arr_down} alt="" /></div>
            </>
    
        )
    }
   
}

export default ReadMore;