import React from 'react';
import HOne from '../h-one';
import Parser from 'html-react-parser';

import ServerApi from '../../services/server-api-service';
import FormCheckService from '../../services/form-check-service';
import CookiesManager from '../../services/cookies-manager';
import Helmet from 'react-helmet';
import './sell-page-style.css';
class SellPage extends React.Component {
    serverApi = new ServerApi();
    formCheckService = new FormCheckService();
    cookiesManager = null;
    constructor(props) {
        super(props);
        this.state = {
            contactInfo: {
                userComment: "",
                userEmail: "",
                userFullName: "",
                userPhone: "",
                userAddress: ""
            }
        }
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        this.cookiesManager = new CookiesManager();
    }

    componentWillMount(){
        if(this.props.saveState) this.props.saveState({currentPage:'SellPage'})
    }

    handleInput(event) {
        this.setState({
            contactInfo: {
                ...this.state.contactInfo,
                [event.target.name]: event.target.value
            }
        })
    }

    renderAnswer() {
        let { dictionary } = this.props;
        const okeyIcon = require('../../elements//icons/okey.png').default
        if (this.state.contactIsSended) {
            return (
                <div>
                    <img src={okeyIcon} className="IconFormSmall" alt=''></img>
                    {dictionary.thank_you_short}
                </div>
            );
        }
    }

    handleSubmit(event) {
        if (!this.formCheckService.checkContactForm(this.state.contactInfo)) {
            this.serverApi.sendForm(this.state.contactInfo)
                .then((data) => {
                    this.cookiesManager.set('exit_pop','1',365)
                    if (this.state.debug) {
                        console.log(data);
                    }
                    if (event.target.name === "BottomForm") {
                        this.setState({
                            contactIsSended: true
                        })
                    } else {
                        this.setState({
                            isVisibleThanksModal: true
                        })
                    }

                })
                .catch((error) => {
                    console.log(`ERROR: fetch sendForm ... -> Component: Wizard-Page ERROR:${error}`);
                })
        }
    }

    renderContactForm() {
        const iconLock = require('../../elements/icons/s_secured.png').default;
        let { dictionary } = this.props;
        return (
            <div className="SellPageContactForm">
                <div className={"SectionHdr"}>
                    <HOne title={dictionary.sell_with_us_form_title} addclass={"small"} />
                </div>
                <div className={"FormSubtitle"}>
                    {dictionary.sell_with_us_form_subtitle}
                </div>
                <div className="SellContactDiv">
                    <div className="ContactFormInputs">
                        <div className={"NamePhone"}>
                            <div className={"Name"}>
                                <input type="text" name="userFullName" className="Input " placeholder={dictionary.full_name} value={this.state.contactInfo.userFullName} onChange={this.handleInput} />
                            </div>
                            <div className={"Phone"}>
                                <input type="text" name="userPhone" className="Input" placeholder={dictionary.phone} value={this.state.contactInfo.userPhone} onChange={this.handleInput} />
                            </div>
                        </div>
                        <div className={"Address"}>
                            <input type="text" name="userAddress" className="Input" placeholder={dictionary.address} value={this.state.contactInfo.address} onChange={this.handleInput} />
                        </div>
                        <div className={"Email"}>
                            <input type="text" name="userEmail" className="Input" placeholder={dictionary.email} value={this.state.contactInfo.userEmail} onChange={this.handleInput} />
                        </div>
                        <div className={"Message"}>
                            <textarea type="text" name="userMessage" className="Input DealMessage" placeholder={dictionary.message} value={this.state.contactInfo.userComment} onChange={this.handleInput} />
                        </div>
                    </div>
                </div>
                <div className="SecureDiv">
                    <img className="SecureIcon" src={iconLock} alt="" />
                    <span className="Text">
                        {dictionary.information_secure}
                    </span>
                </div>
                <div className="ContactFormBottom" style={this.state.contactIsSended ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}>
                    {this.renderAnswer()}
                    <button name="BottomForm" className="Button" id="ButtonSubmit" onClick={this.handleSubmit}>{dictionary.submit}</button>

                </div>
            </div>
        )
    }

    render() {
        const pageLogo = require('../../elements/images/sell_page.png').default;
        const presentationImg = require('../../elements/images/presentation.png').default;
        const serviceImg = require('../../elements/images/service.png').default;
        const marketingImg = require('../../elements/images/marketing.png').default;
        let { dictionary } = this.props;
        let parsedDescription = Parser(dictionary.sell_with_us_desc);
        let parsedPresentationTitle = Parser(dictionary.sell_with_us_presentation_title);
        let parsedPreentationDescription = Parser(dictionary.sell_with_us_presentation_desc);
        let parsedMarketingTitle = Parser(dictionary.sell_with_us_marketing_title);
        let parsedMarketingDescription = Parser(dictionary.sell_with_us_marketing_desc);
        let parsedServiceTitle = Parser(dictionary.sell_with_us_service_title);
        let parsedServiceDescription = Parser(dictionary.sell_with_us_service_description);
        return (
            <div className={"SellPage MenuSpace"}>
                <Helmet>
                    <title> {dictionary.sell} | Smart Luxe</title>
                    <meta name="description" content={dictionary.sell_with_us_desc} />
                </Helmet>
                <div className="PageLogo">
                    <img id="PageLogoImg" src={pageLogo} alt="Logo"></img>
                    <div id="PageLogoTitle"><HOne title={dictionary.sell_with_us} line={"large"} addclass={"lagresize"} /></div>
                </div>
                {/* 
                    OLD

                <div className={"SellPageDescrLine"}>
                    <div className={"SellPageDescr"}>
                        {parsedDescription}
                    </div>
                </div> */}



                <div className="SellPageBody">
                    <div className="SellPageDescription">
                        <div className="SellPageDescriptionText">
                        {parsedDescription}
                        </div>
                    </div>

                    <div className="SellPageElements">
                        <div className="SellPageItem">
                            <img src={presentationImg} alt='presentation' className="ImgPresentation"/>
                            <div className="SellPageItemText">
                                <HOne align="left" title={parsedPresentationTitle} />
                                <div className="SellPageItemTextBody">{parsedPreentationDescription}</div>
                            </div>
                        </div>

                        <div className="SellPageItem SellPageItemCt">

                            <div className="SellPageItemTextRt">
                                <HOne align="right" title={parsedMarketingTitle} />
                                <div className="SellPageItemTextBody">{parsedMarketingDescription}</div>
                            </div>
                            <img src={marketingImg} alt='marketing' className="ImgMarketing" />
                        </div>

                        <div className="SellPageItem">
                            <img src={serviceImg} alt='service' className="ImgService" />
                            <div className="SellPageItemText">
                                <HOne align="left" title={parsedServiceTitle} />
                                <div className="SellPageItemTextBody">{parsedServiceDescription}</div>
                            </div>
                        </div>
                    </div>



                    {/* <div>
                        <HOne title={dictionary.about_us_title} align={"center"} />
                    </div> */}
                </div>
                {this.renderContactForm()}
            </div>
        )
    };

}

export default SellPage;