import React from 'react';
import {
    BrowserRouter as router,
    withRouter,
    Link,
} from "react-router-dom";

// import SocialBlockLinkIcons from '../social-block';
import LanguageSwitch from '../language-switch';
import Menu from '../menu';
import './header-style.css';
import NeighborhoodsModal from '../neighborhoods-modal';
import Parser from "html-react-parser";


// function FindMyDeal (closeModal) {


//     if (true) {
//         return (
//             <Link className="Button" to={`/find-my-deal`} onClick={closeModal}>
//                 FIND MY DEAL
//             </Link>
//         );
//     }
//     else return null;
// }

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            debug: false,
            neighborhoodsIsVisible: false,
            mobileMenuIsVisible: false
        }

        this.handleNeighborhoods = this.handleNeighborhoods.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    handleNeighborhoods() {
        this.setState((state) => {
            return { neighborhoodsIsVisible: !state.neighborhoodsIsVisible }
        });
    }

    handleMobileMenu() {
        this.setState((state) => {
            return { mobileMenuIsVisible: !state.mobileMenuIsVisible }
        });
    }


    closeModal() {
        this.setState({
            neighborhoodsIsVisible: false,
            mobileMenuIsVisible: false
        })
    }

    FindMyDeal() {
        if (true) {
            return (
                <Link className="Button" to={`/find-my-deal`} onClick={this.closeModal}>
                    {this.props.dictionary.find_my_deal}
                </Link>
            );
        }
    }

    handlePushFindMyDeal=()=>{
        this.props.history.push('/find-my-deal');
    }

    renderDynamicHeader() {
        const logo = require('../../elements/images/logo_negative_no_text.png').default;
        let { pageScroll, dictionary } = this.props;
        if (pageScroll > 140&&(!document.location.pathname.includes('/selection')&&!document.location.pathname.includes('/cаmpaign'))) {
            return (
                <div className="HeaderBottomPart" id="DynamicHeader">
                    <div className="DynamicDiv">
                        <div className="Logo">
                            <Link to="/" onClick={this.closeModal} >
                                <img src={logo} alt="" className={"LogoImg"}></img>
                            </Link>
                        </div>
                        <Menu handleNeighborhoods={this.handleNeighborhoods}
                            closeModal={this.closeModal}
                            dictionary={dictionary}
                        />
                        <LanguageSwitch forMobile={true} />
                    </div>
                    <NeighborhoodsModal
                        data={this.props.neighboorhoods}
                        isVisible={this.state.neighborhoodsIsVisible}
                        closeModal={this.closeModal}
                        dictionary={dictionary}
                    />
                </div>
            )
        }
    }



    render() {
        const logo = require('../../elements/images/logo_positive_center.svg').default;
        const logo_left = require('../../elements/images/logo_positive_left.svg').default;

        let { mobileMenuIsVisible } = this.state;
        let { neighboorhoods, dictionary,allowedLanguages,articlesTranslations } = this.props;
        let mobileMenu = () => {
            if (mobileMenuIsVisible) {
                return (
                    <div className="MobileMenu">
                        <div className={mobileMenuIsVisible ? "HeaderTopPart HeaderTopPartMobile" : "HeaderTopPart"}>
                            <LanguageSwitch forMobile={true} />
                            {/*{this.FindMyDeal()}*/}
                        </div>
                        <div className="MobileMenuItems">
                            <Menu
                                handleNeighborhoods={this.handleNeighborhoods}
                                neighboorhoods={neighboorhoods}
                                dictionary={dictionary}
                                neighborhoodsIsVisible={this.state.neighborhoodsIsVisible}
                                closeModal={this.closeModal}
                            />

                            <div className="CloseModal" onClick={this.closeModal}>

                            </div>
                        </div>

                    </div>
                )
            }
            else return null;
        }

        let renderBody = () => {
            return (
                <div className="TopHeader">
                    <div className="HeaderMain">
                        <div className={"HeaderTopLine"}>
                            <div className="HeaderTopPart">
                                {/*<div className="SocialBlock">*/}
                                {/*<SocialBlockLinkIcons />*/}
                                {/*<div className="FindMyDealButton">*/}
                                {/*   {this.FindMyDeal(this.closeModal)}*/}
                                {/*</div>*/}

                                {/*</div>*/}
                                <div className={"LogoLine"}>
                                    <div className="Logo">
                                        <Link to="/" onClick={this.closeModal}>
                                            <img src={logo} alt=""></img>
                                        </Link>
                                    </div>
                                </div>
                                <LanguageSwitch
                                articlesTranslations={articlesTranslations}
                                 forMobile={false} 
                                 allowedLanguages={allowedLanguages}
                                 />
                                <div className={"PoweredBy"} onClick={this.handlePushFindMyDeal}>
                                    {Parser(dictionary.slogan_powered_by)}
                                </div>
                            </div>

                        </div>
                       {!((document.location.pathname.includes('/selection')) && (document.location.pathname.includes('/cаmpaign/')))?(
                        <div className="HeaderBottomPart">
                            <Menu handleNeighborhoods={this.handleNeighborhoods}
                                closeModal={this.closeModal}
                                dictionary={dictionary}
                            />
                        </div>
                       ):(null)}

                        {this.renderDynamicHeader()}

                        {/* Neughborhoods modal screen */}
                        <NeighborhoodsModal
                            data={this.props.neighboorhoods}
                            isVisible={this.state.neighborhoodsIsVisible}
                            closeModal={this.closeModal}
                            dictionary={dictionary}
                        />
                    </div>
                    <div className="HeaderMainMobile">
                        <div className="HeaderTop">
                            <div className="Logo">
                                <Link to="/" onClick={this.closeModal}>
                                    <img src={logo_left} alt=""></img>
                                </Link>
                            </div>
                            <div className="MenuButtonPart">
                                <div className={mobileMenuIsVisible ? "MenuButton MenuButtonActive" : "MenuButton"} onClick={() => this.handleMobileMenu()}>Menu</div>
                            </div>
                        </div>
                        {mobileMenu()}
                    </div>

                </div>
            )

        }

        return (
            <>
                {(!document.location.pathname.includes('/selection')&&!document.location.pathname.includes('/cаmpaign'))?renderBody():null}
            </>
        )
    }
}

export default withRouter(Header);