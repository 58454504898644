import React from 'react';
import HOne from "../h-one";
import UserForm from '../user-form';
import Parser from 'html-react-parser';
import FormCheckService from '../../services/form-check-service';
import ServerApi from '../../services/server-api-service';
import CookiesManager from '../../services/cookies-manager';
import { AnimatePresence, motion } from 'framer-motion';
import './join-team-modal-style.css';
class JoinTeamModal extends React.Component {
    formCheckService = new FormCheckService();
    serverApi = new ServerApi();
    cookiesManager = null;
    constructor(props) {
        super(props)
        this.state = {
            debug: false,
            contactForm: {
                project: 'Join the Team',
                userFullName: '',
                userPhone: '',
                userEmail: '',
                userMessage: ''
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }

    componentDidMount(){
        this.cookiesManager = new CookiesManager();
    }

    handleInput(event) {
        this.setState({
            contactForm: {
                ...this.state.contactForm,
                [event.target.name]: event.target.value
            }
        })
    }

    clearForm = ()=>{
        this.setState({
            ...this.state,
            contactForm: {
                project: 'Join the Team',
                userFullName: '',
                userPhone: '',
                userEmail: '',
                userMessage: ''
            }
        })
    }
    handleSubmit(event) {
        event.preventDefault();
        if (!this.formCheckService.checkContactForm(this.state.contactForm)) {
            this.serverApi.sendForm(this.state.contactForm)
                .then((data) => {
                    this.cookiesManager.set('exit_pop', '1', 365)
                    if (this.state.debug) {
                        console.log(data);
                    }
                    this.props.openThanksModal();
                })
                .catch((error) => {
                    console.log(`ERROR: fetch sendForm ... -> Component: join team modal ERROR:${error}`);
                })
        }


    }
    // renderBody() {
    //     // const joinTheTeam = require('../../elements/images/join_the_team.png').default;
    //     let { dictionary } = this.props;
    //     let parsedJoinDesc = Parser(dictionary.join_desc)
    //     if (this.props.isVisible) {
    //         return (
    //             <AnimatePresence exitBeforeEnter>
    //                 <motion.div
    //                     initial={{ opacity: 0 }}
    //                     exit={{ opacity: 0 }}
    //                     animate={{ opacity: 1 }}
    //                     className="ModalBlur"
    //                     onClick={this.props.handleModal}
    //                 >
    //                     <div className="Modal" id="JoinTeamModal">
    //                         <div className={"ModalBody"}>
    //                             <div>
    //                                 <HOne title={dictionary.join_the_team} align="left" />
    //                                 <div className="JoinTeamModalDesc">
    //                                     {parsedJoinDesc}
    //                                 </div>
    //                                 {/* <h1>JOIN THE TEAM</h1> */}
    //                                 {/* <svg xmlns="http://www.w3.org/2000/svg" width="138.247" height="2" viewBox="0 0 138.247 2">
    //                             <defs>
    //                                 <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
    //                                     <stop offset="0" stop-color="#a96903" />
    //                                     <stop offset="0.258" stop-color="#aa6a02" />
    //                                     <stop offset="0.352" stop-color="#b07102" />
    //                                     <stop offset="0.418" stop-color="#b97d02" />
    //                                     <stop offset="0.472" stop-color="#c78e01" />
    //                                     <stop offset="0.518" stop-color="#daa401" />
    //                                     <stop offset="0.557" stop-color="#f0be00" />
    //                                     <stop offset="0.579" stop-color="#ffd100" />
    //                                     <stop offset="1" stop-color="#a96903" />
    //                                 </linearGradient>
    //                             </defs>
    //                             <g id="Сгруппировать_345" data-name="Сгруппировать 345" transform="translate(-2822.173 274.933)">
    //                                 <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="138.247" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
    //                             </g>
    //                         </svg> */}

    //                                 <form className="JoinTeamForm">
    //                                     <div>
    //                                         <input onChange={this.handleInput} name='userFullName' className="Input" placeholder={dictionary.full_name} type="text" value={this.state.contactForm.userFullName}></input>
    //                                         <input onChange={this.handleInput} name='userPhone' className="Input" placeholder={dictionary.phone} type="text" value={this.state.contactForm.userPhone}></input>
    //                                         <input onChange={this.handleInput} name='userEmail' className="Input" placeholder={dictionary.email} type="text" value={this.state.contactForm.userEmail}></input>
    //                                         <textarea onChange={this.handleInput} name='userMessage' className="Input" placeholder={dictionary.message} type="text" value={this.state.contactForm.userMessage}></textarea>
    //                                         <button onClick={this.handleSubmit} className='Button'>{dictionary.submit}</button>
    //                                     </div>
    //                                     {/* <div>
    //                                 <img src={joinTheTeam} className="JoinTeamImg" />
    //                             </div> */}
    //                                 </form>
    //                             </div>
    //                             <div >

    //                             </div>
    //                             <div onClick={this.props.handleModal}>
    //                                 <svg className="CloseIcon" xmlns="http://www.w3.org/2000/svg" width="21.683" height="21.683" viewBox="0 0 21.683 21.683">
    //                                     <g id="Сгруппировать_348" data-name="Сгруппировать 348" transform="translate(-1189.793 -281.793)">
    //                                         <line id="Линия_85" data-name="Линия 85" x1="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
    //                                         <line id="Линия_86" data-name="Линия 86" x2="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
    //                                     </g>
    //                                 </svg>

    //                             </div>
    //                         </div>
    //                     </div>
    //                     {/* <div className="ModalBlur" onClick={this.props.handleModal}></div>*/}
    //                 </motion.div>
    //             </AnimatePresence>
    //         )
    //     }
    // }

    render() {
        let { dictionary, width, country } = this.props;
        let {contactForm} = this.state;
        let parsedJoinDesc = Parser(dictionary.join_desc)
        return (

            <AnimatePresence exitBeforeEnter>

                {this.props.isVisible && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="ModalBlur"
                    // className="ModalBlur"
                    // onClick={this.props.handleModal}
                    >
                        <div className="Modal" id="JoinTeamModal">
                            <div className={"ModalBody"}>
                                <div>
                                    <HOne title={dictionary.join_the_team} align="left" />
                                    <div className="JoinTeamModalDesc">
                                        {parsedJoinDesc}
                                    </div>
                                    {/* <h1>JOIN THE TEAM</h1> */}
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="138.247" height="2" viewBox="0 0 138.247 2">
                                <defs>
                                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#a96903" />
                                        <stop offset="0.258" stop-color="#aa6a02" />
                                        <stop offset="0.352" stop-color="#b07102" />
                                        <stop offset="0.418" stop-color="#b97d02" />
                                        <stop offset="0.472" stop-color="#c78e01" />
                                        <stop offset="0.518" stop-color="#daa401" />
                                        <stop offset="0.557" stop-color="#f0be00" />
                                        <stop offset="0.579" stop-color="#ffd100" />
                                        <stop offset="1" stop-color="#a96903" />
                                    </linearGradient>
                                </defs>
                                <g id="Сгруппировать_345" data-name="Сгруппировать 345" transform="translate(-2822.173 274.933)">
                                    <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="138.247" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
                                </g>
                            </svg> */}


                                    {/* <form className="JoinTeamForm">
                                        <div>
                                            <input onChange={this.handleInput} name='userFullName' className="Input" placeholder={dictionary.full_name} type="text" value={this.state.contactForm.userFullName}></input>
                                            <input onChange={this.handleInput} name='userPhone' className="Input" placeholder={dictionary.phone} type="text" value={this.state.contactForm.userPhone}></input>
                                            <input onChange={this.handleInput} name='userEmail' className="Input" placeholder={dictionary.email} type="text" value={this.state.contactForm.userEmail}></input>
                                            <textarea onChange={this.handleInput} name='userMessage' className="Input" placeholder={dictionary.message} type="text" value={this.state.contactForm.userMessage}></textarea>
                                            <button onClick={this.handleSubmit} className='Button'>{dictionary.submit}</button>
                                        </div>
                                    </form> */}

                                    <UserForm
                                        clearForm={this.clearForm}
                                        contactForm={contactForm}
                                        handleInput={this.handleInput}
                                        width={width}
                                        saveCurrentSelectedCountry={this.saveCurrentSelectedCountry}
                                        dictionary={dictionary}
                                        country={country}
                                        className={'UserForm'}
                                        openThanksModal={this.props.openThanksModal}
                                        style={{ textAlign: 'center', justifyContent: 'center',display:'block' }}
                                    />

                                </div>
                                <div >

                                </div>
                                <div onClick={this.props.handleModal}>
                                    <svg className="CloseIcon" xmlns="http://www.w3.org/2000/svg" width="21.683" height="21.683" viewBox="0 0 21.683 21.683">
                                        <g id="Сгруппировать_348" data-name="Сгруппировать 348" transform="translate(-1189.793 -281.793)">
                                            <line id="Линия_85" data-name="Линия 85" x1="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
                                            <line id="Линия_86" data-name="Линия 86" x2="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
                                        </g>
                                    </svg>

                                </div>
                            </div>
                        </div>
                        <div className="ModalBlurDiv" onClick={this.props.handleModal}></div>
                    </motion.div>
                )}
            </AnimatePresence>

        )
    }
}
export default JoinTeamModal