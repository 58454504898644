import React from 'react';
import Parser from 'html-react-parser';
import { AnimatePresence, motion } from 'framer-motion';

import CookiesManager from '../../services/cookies-manager';
import ServerApi from '../../services/server-api-service';
import FormCheckService from '../../services/form-check-service';

import './find-my-deal-modal-style.css';
import HOne from "../h-one";
const PAGES = [
    {name:'/',value:"homepage"},
    {name:"projects",value:'projects'},
    {name:"team",value:'team'},
    {name:"contact",value:'contact'},
    {name:"about",value:'about'},
    {name:"neighborhood",value:'neighborhoods'},
    {name:"sell",value:'sell'},
    {name:"find-my-deal",value:'wizard'}
]
class FindMyDealModal extends React.Component {
    
    formCheckService = new FormCheckService();
    serverApi = new ServerApi();
    cookiesManager = null;

    constructor(props) {
        super(props)
        this.state = {
            info: {
                userFullName: '',
                userEmail: '',
            },
            requestOk: false
        }
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getProjectName(){
        PAGES.forEach((item)=>{
            if(document.location.pathname.includes(item.name)){
               
                    this.setState({
                        info:{
                            ...this.state.info,
                            project:item.value
                        }
                    })
            }
        }) 
    }

    componentWillUpdate(prevProps){
        if(this.props.location!==prevProps.location){
           this.getProjectName()
        }
    }

    componentWillMount(){
        this.cookiesManager = new CookiesManager();
        this.getProjectName()
    }

    handleInput(event) {
        this.setState({
            info: {
                ...this.state.info,
                [event.target.name]: event.target.value
            }
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        if (!this.formCheckService.checkContactForm(this.state.info)) {
            this.serverApi.sendForm(this.state.info)
                .then((data) => {
                    if (this.state.debug) {
                        console.log(data);
                    }
                    this.cookiesManager.set('exit_pop', '1', 365)
                    this.setState({
                        requestOk: true,
                        isVisibleContactFormModal: false,
                    })

                })
                .catch((error) => {
                    console.log(`ERROR: fetch sendForm ... -> Component: Wizard-Page ERROR:${error}`);
                })
        }
    }


    render() {
        let smartSmall = require('../../elements/images/deal-ins.jpg').default;
        let { dictionary } = this.props;
        let body = null;
        let parsedDesc = Parser(dictionary.never_miss_desc)
        if (this.state.requestOk) {
            body = (
                <>
                    {/* <div>
                        <HOne title={dictionary.thank_you} align="left" />
                        {dictionary.never_miss_thank_you}
                    </div> */}

                            <HOne addclass="ThanksTitle" title={dictionary.thank_you} />
                            <div className="ModalMessage">
                                <div className="ModalMessageText">
                                    {dictionary.never_miss_thank_you}
                                </div>
                            </div>
                </>
            )
        } else {
            body = (
                <>
                    <div className="DealBlocks">
                        <div className="DealLeftBlock">
                            <HOne title={dictionary.never_miss} align="left" />
                            <div className="DealDesc">
                                {parsedDesc}
                            </div>

                            <form onSubmit={this.handleSubmit}>
                                <div className={"formLine"}>
                                    <input name="userFullName" className="Input DealInput" placeholder={dictionary.full_name} onChange={this.handleInput} value={this.state.info.userFullName}></input>
                                </div>
                                <div className={"formLine"}>
                                    <input name="userEmail" className="Input DealInput" placeholder={dictionary.email} onChange={this.handleInput} value={this.state.info.userEmail}></input>
                                </div>
                                <div>
                                    <input type="submit" value={dictionary.sign_up} className="DealSubmit"></input>
                                </div>
                            </form>
                        </div>
                        <div>
                            <img className="DealImg" src={smartSmall} alt=""></img>
                        </div>
                    </div>
                    <div onClick={this.props.closeModal}>
                        <svg className="CloseIcon" xmlns="http://www.w3.org/2000/svg" width="21.683" height="21.683" viewBox="0 0 21.683 21.683">
                            <g id="Сгруппировать_348" data-name="Сгруппировать 348" transform="translate(-1189.793 -281.793)">
                                <line id="Линия_85" data-name="Линия 85" x1="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
                                <line id="Линия_86" data-name="Линия 86" x2="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
                            </g>
                        </svg>

                    </div>
                </>
            )
        }

        // let page = null;
        // if (this.props.isVisible) {
        //     page = (
        //         <motion.div
        //             initial={{ opacity: 0 }}
        //             exit={{ opacity: 0 }}
        //             animate={{ opacity: 1 }}
        //         >
        //             <div className="Modal">
        //                 <div className="ModalBody">
        //                     {body}
        //                 </div>
        //             </div>
        //             <div className="ModalBlur" onClick={this.props.closeModal}></div>
        //         </motion.div>
        //     )
        // } else {
        //     page = null;
        // }

        return (
            <>
                <AnimatePresence exitBeforeEnter>
                    {/* {page} */}
                    {this.props.isVisible && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            exit={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="ModalBlur"

                        >
                            <div className="Modal">
                                <div className={this.state.requestOk?('Body'):("ModalBody")} >
                                    {body}
                                </div>
                            </div>
                            <div className="ModalBlurDiv" onClick={this.props.closeModal}></div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </>
        )
    }
}

export default FindMyDealModal;