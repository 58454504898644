import React from 'react';
import { withRouter } from 'react-router-dom';
// import GoogleMap from '../google-map';
import OffersGrid from '../offers-grid';
import ServerApi from '../../services/server-api-service';
import CookiesManager from '../../services/cookies-manager';
import HOne from "../h-one";
import AgentCard from '../agent-card';

// import HeaderSelection from '../header-selection';
import './selection-page-style.css';

class SelectionPage extends React.Component {
  serverApi = new ServerApi();
  cookiesManager =  new CookiesManager();
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
      listingExpiredList: [],
      data: {
        // title: 'Title',
        // notes: 'Notes',
        // date: new Date().toTimeString(),
      }
    }
  }

  componentDidMount(){
  }

  componentWillMount() {
    if (this.cookiesManager.get('exit_pop') === undefined || this.cookiesManager.get('exit_pop') !== '1') {
      this.cookiesManager.set('exit_pop', 1, 365);
    }
    if (this.cookiesManager.get(this.props.match.params.id)) {
      let listingExpiredList = JSON.parse(this.cookiesManager.get(this.props.match.params.id))
      if (listingExpiredList) {
        this.setState({
          listingExpiredList: listingExpiredList
        })
      }
    }
    if (this.props.match.params.id) {
      this.serverApi.getSelection(this.props.match.params.id, this.props.match.params.agentId)
        .then((data) => {
          this.setState({
            data: data
          })
          this.props.saveSelectionListings(data.listings, this.props.match.params.id, this.props.match.params.agentId);
          this.props.saveCurrentSelectionListing(null);
        })
        .catch((error) => {
          console.log(`ERROR: fetch getSelection ... -> Component: Seletion-Page ERROR:${error}`);
          //this.props.history.replace({pathname:`/page-not-found`});
        })
    }
    let initialData = {
      mapSide: "left",
      selectionHeader: true,
      showSelectionToolBar: false,
      selectionMapFloating: false,
      currentPage: 'SelectionPage'
    }
    this.props.saveState(initialData);
    // this.props.saveState('mapSide','left');
    // this.props.saveState('selectionHeader',true);
    // this.props.saveState('showSelectionToolBar',false);
    // this.props.saveState('selectionMapFloating',false);

    let page = document.getElementsByClassName('Page')[0];
    console.log(page)
    if (page != null) {
      if (this.props.widthRouterMark !== page.clientWidth) {
        this.props.saveState({
          widthRouterMark: page.clientWidth,
          heightRouterMark: page.clientHeight
        })
      }
    }
  }

  // componentWillUpdate(){
  //   let page  = document.getElementsByClassName('Page')[0];
  //   console.log(page)
  //   if(page!=null){
  //      if(this.props.widthRouterMark!==page.clientWidth){
  //       this.props.saveState({
  //           widthRouterMark:page.clientWidth,
  //           heightRouterMark:page.clientHeight
  //       })
  //      }
  //   }
  // }

  render() {
    let { dictionary, width, agentId, hoveredMarkerId, saveState } = this.props;
    let { title, notes, listings, date, agent } = this.state.data;
    const contactWA = require('../../elements/images/contactWA.png').default;
    //const logoImg = require('../../elements/images/logo_positive_center.png').default;
    let body = null;
    if (this.state.data !== undefined && listings !== undefined) {
      body = (
        <div className="Page SelectionPage">
          <div className="PageBody">
            <AgentCard
              width = {width}
              title={title}
              notes={notes}
              agent={agent}
              date={date}
            />
            {/* <div className={"NotesAndAgent"}>

              <div className="Notes">
                <HOne addclass={'Title'} title={title} align="left" />
                {notes}
                <div className="Date">
                  Selection prepared: {date}
                </div>
              </div>

              <div className={"Agent"}>
                <div className='AgentTop'>
                  <div className='Photo'>
                    <img className={"AgentImg"} src={`https://ngirealty.com/img/profiles/${agent.profile_picture}`} />
                  </div>
                  <div className='Info'>
                    <div className='Name'>
                      {agent.name}<br />
                    </div>

                    {agent.title}<br />
                    Miami - FL Lauderdate
                    <HOne title='' line={'large'} lineWidth={'270'}></HOne>
                    <div className='TableBlock'>
                      <table>
                        <thead></thead>
                        <tbody>
                          <tr>
                            <td className='InfoIcon'>M</td>
                            <td>{agent.phone}</td>
                          </tr>
                          <tr>
                            <td className='InfoIcon'>E</td>
                            <td><a href={`mailto:${agent.email}`}>{agent.email}</a></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>

                <div className='AgentBottom'>
                  <a target="_blank" href={`https://wa.me/${agent.phone}`}><img className="ContactWA" src={contactWA} /></a>
                </div>

              </div>
            </div> */}

            <div className="SelectionLine">
              <div className="SearchBlock">
                <div className="TopPart">
                  {/* <div className="MainMap">
                   <GoogleMap
                      width="100%"
                      height="100%"
                      // coord={{
                      //   lat: 25.78243900,
                      //   lng: -80.19197950
                      // }}
                      data={listings}
                      dataCenter={true}
                      dataMarkers={true}
                    /> 
                    <GoogleMapStaticCustom
                      data={listings}
                    />
                  </div> */}
                  <div className="Offers">
                    <OffersGrid
                      hoveredMarkerId={hoveredMarkerId}
                      selectionId={this.props.match.params.id}
                      title={dictionary.featured_deals}
                      data={listings}
                      width={width}
                      dictionary={dictionary}
                      verticalList={true}
                      agentId={agentId}
                      selection={true}
                      dealScore={false}
                      smartDealEnable={false}
                      listingExpiredList={this.state.listingExpiredList}
                      saveState={saveState}
                    />
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      )
    }

    return (
      <>
        {/* <HeaderSelection dictionary={this.props.dictionary}/> */}
        {body}
      </>
    )
  };

}

export default withRouter(SelectionPage);