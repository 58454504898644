import React from 'react';
import {
    BrowserRouter as   Route,
    Link,
} from "react-router-dom";

import './find-my-deal-block-style.css';
class FindMyDealBlock extends React.Component{

    render() {
        //const leftImage = require('../../elements/images/dots_left.png').default;
        //const rightImage = require('../../elements/images/dots_right.png').default;
        const iconSearch = require('../../elements/icons/search.png').default;
        let {dictionary,link ="/find-my-deal"} = this.props;
        let title=dictionary.deal_finder;
        if(this.props.title!==undefined){
            title = `${dictionary.find_my_deal_in} ${this.props.title}`;
        }

        return (
            <div className={"FindMyDeal"}>
                    <div className="PoweredMain">
                        {/* <img src={leftImage} alt=""></img> */}
                        <div className="leftBgrImage"></div>
                        <div className="CenterBlock">
                            <img className="Icon" src={iconSearch} alt=""></img>
                            <h1>{title}</h1>
                            <span className="TextBlock">
                                {dictionary.deal_finder_desc}
                            </span>
                            <br />
                            <div>
                                <Link to={link} className="Button">{dictionary.find_my_deal}</Link>
                            </div>
                        </div>
                        <div className="rtBgrImage"></div>
                        {/* <img src={rightImage} alt=""></img> */}
                    </div>
            </div>
        )
    }
}

export default FindMyDealBlock;