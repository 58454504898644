import React from 'react';
import StringProcessingService from '../../services/string-processing-service';
import {Link} from 'react-router-dom';
class NeighborhoodsItem extends React.Component {
    stringProcessingService = new StringProcessingService();

    renderBody() {
        if (this.props.data!==undefined) {
            let {data,slider=false} = this.props;
            return (
                <div>
                    <Link to={`/neighborhood/${data.id}/${this.stringProcessingService.StringFormatUrl(data.name)}`}>
                        <div className="NeighboorSliderItem" id = {!slider?"NeighboorGridItem":""}>
                            <div className="Overlay"></div>
                            <img src={`https://ngirealty.com/img/smart_luxe/areas/thumbs/${data.image_cover}`} alt=""></img>
                            <div>{data.name}</div>
                        </div>
                    </Link>
                </div>
            )

        }
    }
    render() {
        return (
            <>
                {this.renderBody()}
            </>
        );
    }
}
export default NeighborhoodsItem;