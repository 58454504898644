import * as configurations from '../configuration/servers';
import * as currentConfig from '../configuration/config.json';
import * as languages from '../configuration/languages';
const languageList= require('../configuration/languages/list.json')

let debug = false;
let currentServer = configurations[currentConfig.currentServer];
let siteUrl= '';
if(typeof window !== "undefined"){
    
        siteUrl = window.location.protocol + "//" + window.location.hostname + "/"; 
} 




let languagePrefix ='en';

for(let i =0;i<languageList.length;i++){
    if(siteUrl.includes(`${languageList[i].name}.`)){
        languagePrefix = languageList[i].name
    }
}

// if (siteUrl.includes('es.')) {
//     languagePrefix = 'es'
// }

// if (siteUrl.includes('pt.')) {
//     languagePrefix = 'pt'
// }


let currentLanguage = languages[languagePrefix];
if(typeof localStorage !== "undefined"){
    localStorage.setItem('lang', languagePrefix);
}

if (debug) {
    console.log(currentLanguage);
}

if (siteUrl.includes('axis')) {
    currentServer = configurations["axis"];

} else if (siteUrl.includes('onserv')) {
    currentServer = configurations["onserv"];
   // siteUrl = configurations["onserv"].serverApi;


} else if (siteUrl.includes('track21')) {
    currentServer = configurations["track"];

}
else {
    currentServer = configurations["prod"];
}

export default class ConfigurationService {

    getCurrentServer() {
        if (debug) {
            console.log(currentServer);
        }
        return currentServer;
    }

    getCurrentSite() {
        return siteUrl;
    }

    getCurrentLanguage() {
        return languages[localStorage.getItem('lang')];
    }

    switchLanguage(lang) {
        switch (lang) {
            case 'en': {
                localStorage.setItem('lang', 'en');
                break;
            }
            case 'es': {
                localStorage.setItem('lang', 'es');
                break;
            }
            default: {
                localStorage.setItem('lang', 'en');
                break;
            }
        }
    }

    getCurrentLanguagePrefix() {
        return languagePrefix;
    }

    checkDomainName(){
        // let domain = document.location.hostname;
        // let domainZone = 'com'
        // let protocol = document.location.protocol;
        // let port = '';
        
        // if(domain.includes('.test')||domain.includes('.qa')){
        //     domainZone="test:3000"
        //     port=":3000"
        // }
        // if(domain+port===`smartluxe.${domainZone}`){

        // }else{
        //     let flagLocale= false;
        //     languageList.map((locale)=>{
        //         if(domain+port===`${locale.name}.smartluxe.${domainZone}`){
        //             flagLocale=true;
        //         }else {
        //             if(domain.includes(`${locale.name}.`)){
        //                 flagLocale=true;
        //                 window.location.replace(`${protocol}//${locale.name}.smartluxe.${domainZone}${document.location.pathname}`);
        //             }
        //         }
        //     })

        //     if(!flagLocale){
        //         window.location.replace(`${protocol}//smartluxe.${domainZone}${document.location.pathname}`);
        //     }
        // }
        

        return null;
    }

    // insertLinksToHead(prev, next) {
    //     try {

    //         if(document.getElementById("canonical") != null){
    //             document.getElementById("canonical").remove();
    //         }
    //         if(document.getElementById("alternate") != null){
    //             document.getElementById("alternate").remove();
    //         }
    //          if( document.getElementById("alternate") == null||
    //             document.getElementById("canonical") == null) {
    //             const seoLinksCan = document.createElement('link');
    //             seoLinksCan.setAttribute('rel', 'canonical');
    //             seoLinksCan.setAttribute('id', 'canonical');
    //             seoLinksCan.setAttribute('href', document.URL);
    //             document.head.appendChild(seoLinksCan);

    //             const seoLinksAlt = document.createElement('link');
    //             seoLinksAlt.setAttribute('rel', 'alternate ');
    //             seoLinksAlt.setAttribute('id', 'alternate');
    //             seoLinksAlt.setAttribute('href', document.URL.replace('//www.', '//mobile.'));
    //             document.head.appendChild(seoLinksAlt);

    //         }
    //         if (document.getElementById("prev") != null) {
    //             document.getElementById("prev").remove();
    //         }
    //         if (document.getElementById("next") != null) {
    //             document.getElementById("next").remove();
    //         }
    //         if (prev) {
    //             const seoLinksPrev = document.createElement('link');
    //             seoLinksPrev.setAttribute('rel', 'prev');
    //             seoLinksPrev.setAttribute('id', 'prev');
    //             seoLinksPrev.setAttribute('href', prev);
    //             document.head.appendChild(seoLinksPrev);
    //         }
    //         if (next) {
    //             const seoLinksNext = document.createElement('link');
    //             seoLinksNext.setAttribute('rel', 'next')
    //             seoLinksNext.setAttribute('id', 'next');
    //             seoLinksNext.setAttribute('href', next);
    //             document.head.appendChild(seoLinksNext);
    //         }
    //         if(document.getElementById('productApplactionLd')!==null&&!document.URL.includes('/product/')){
    //             document.getElementById('productApplactionLd').remove();
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
}

