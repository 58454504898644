import React from 'react';
import './h-one.css';
import Parser from "html-react-parser";


class HOne extends React.Component {

    render() {

        let {title, align="center", mainClass='', addclass, color, line="normal", dictionary,lineWidth ="370"} = this.props; //caps=false

        let draw_line = () => {
            if (line === 'normal')
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="138.247" height="2" viewBox="0 0 138.247 2">
                        <defs>
                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                <stop offset="0" stopColor="#a96903"/>
                                <stop offset="0.258" stopColor="#aa6a02"/>
                                <stop offset="0.352" stopColor="#b07102"/>
                                <stop offset="0.418" stopColor="#b97d02"/>
                                <stop offset="0.472" stopColor="#c78e01"/>
                                <stop offset="0.518" stopColor="#daa401"/>
                                <stop offset="0.557" stopColor="#f0be00"/>
                                <stop offset="0.579" stopColor="#ffd100"/>
                                <stop offset="1" stopColor="#a96903"/>
                            </linearGradient>
                        </defs>
                        <g id="Сгруппировать_94" data-name="Сгруппировать 94" transform="translate(-2822.173 274.933)">
                            <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="138.247" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)"/>
                        </g>
                    </svg>
                )
            else if (line === 'none') {
                return null
            }
            else {
                return (
                    // <svg xmlns="http://www.w3.org/2000/svg" width="437" height="2.65" viewBox="0 0 437 2.65">
                    <svg xmlns="http://www.w3.org/2000/svg" width={lineWidth} height="2.65" viewBox="0 0 437 2.65">
                        <defs>
                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                <stop offset="0" stopColor="#a96903"/>
                                <stop offset="0.258" stopColor="#aa6a02"/>
                                <stop offset="0.352" stopColor="#b07102"/>
                                <stop offset="0.418" stopColor="#b97d02"/>
                                <stop offset="0.472" stopColor="#c78e01"/>
                                <stop offset="0.518" stopColor="#daa401"/>
                                <stop offset="0.557" stopColor="#f0be00"/>
                                <stop offset="0.579" stopColor="#ffd100"/>
                                <stop offset="1" stopColor="#a96903"/>
                            </linearGradient>
                        </defs>
                        <g id="Сгруппировать_13" data-name="Сгруппировать 13" transform="translate(-2822.173 274.933)">
                            <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="437" height="2.65" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)"/>
                        </g>
                    </svg>
                )
            }
        }

        let poweredBy = () => {
            if (this.props.poweredBy) {
                return (
                    <div className={"PoweredBy PoweredByTop"}>
                          {Parser(dictionary.slogan_powered_by)}
                    </div>
                )
            }
            else return null;
        }

        return (
            <div className={`HOne ${mainClass}`} style={{textAlign:align}}>
            <h2 className={addclass} style={{color:color}}>
                {Parser(title)}
            </h2>
                <div className={"Underline"}>
                {draw_line()}
                </div>
                {poweredBy ()}
            </div>
        )
    }
}

export default HOne;