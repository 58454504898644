import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import StringProcessingService from '../../services/string-processing-service';
import ServerApi from '../../services/server-api-service';
import FormCheckService from '../../services/form-check-service';
import ConfigurationService from '../../services/configuration-service';
import Parser from "html-react-parser";
import HOne from "../h-one";
import { SampleNextArrow, SamplePrevArrow } from '../../functions/SliderArrow/SliderArrow.js';
import Slider from 'react-slick';
// import {
//     Link
// } from "react-router-dom";

import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';


import './selection-listing-modal-style.css';
class ListingPageModal extends React.Component {
    stringProcessingService = new StringProcessingService();
    serverApi = new ServerApi()
    constructor(props) {
        super(props);
        this.state = {
            currentImgId: '0',
            currentSlide: props.currentSlide,
        }
    }


    // handleSwitchMedia = (newImage) => {
    //     console.log(newImage);
    //     this.setState({
    //         currentImgId: newImage.Order,
    //         currentImg: newImage.MediaURL
    //     })
    // }


    // renderMediaSwitch() {
    //     let { data } = this.props;
    //     let moreImg = require('../../elements/images/more_image.png').default;
    //     console.log(data);
    //     if (data) {

    //         return (
    //             <div className="MediaSwitchDiv">{data.Media.map((item, index) => {
    //                 if (this.state.currentImgId === item.Order && index < 4) {
    //                     return (
    //                         <div key={index}>
    //                             <div className="MediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, item)}>
    //                                 <img src={item.MediaURL} className="MediaImgSmall" alt="" ></img>
    //                             </div>
    //                         </div>
    //                     )
    //                 } else if (index < 4) {
    //                     return (
    //                         <div key={index}>
    //                             <div className="MediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, item)}>
    //                                 <img src={item.MediaURL} className="MediaImgSmall" alt="" ></img>
    //                                 <div className="Other"></div>
    //                             </div>
    //                         </div>
    //                     )
    //                 } else if (index === 5) {
    //                     return (
    //                         <div key={index}>
    //                             <div className="MediaSwitchItem">
    //                                 <img src={moreImg} className="MediaImgSmall" alt="" ></img>
    //                             </div>
    //                         </div>
    //                     )
    //                 }

    //             })}</div>
    //         )
    //     } else return null

    // }

    closeModal() {
        document.dispatchEvent(new CustomEvent('openModal', { detail: { name: "selectionListingModal", value: false } }))
    }


    renderCounter() {
        let { currentSlide } = this.state;
        let { data } = this.props;
        return (
            <div className="Counter">
                {currentSlide + 1} of {data.Media.length}
            </div>
        )
    }

    renderMedia() {
        let { data, height } = this.props;
        if (this.state.currentImgId === '0') {
            this.setState({
                currentImgId: this.props.data.Media[0].Order,
                currentImg: this.props.data.Media[0].MediaUrl
            })
        }
        const speed= 500;
        const SettingSlider={
            beforeChange: (current, next) => {
                setTimeout(()=>{
                    this.setState({
                        currentSlide:next
                    })
                }, speed)
              },
              speed
        }


        // let SliderItems = this.props.data.Media.map((item, index) => {
        //     if (this.state.currentSlide === index) {
        //         return (
        //             <div key={index}>
        //                 <div className="TopSliderItem" id ="SelectionTopSliderImg" >
        //                     <img  src={item.MediaURL} className="TopSliderImg" alt="" ></img>
        //                 </div>
        //             </div>
        //         )
        //     } else {
        //         return (
        //             <div key={index}>
        //                 <div className="TopSliderItem" id={'SelectionTopSliderImg'}>
        //                     <img src={item.MediaURL} className="TopSliderImg" alt="" ></img>
        //                     <div className="Other"></div>
        //                 </div>
        //             </div>
        //         )
        //     }
        // })

        let SliderItems = this.props.data.Media.map((item, index) => {
            if (this.state.currentSlide === index) {
                return (
                    <div key={index}>
                        <div className="TopSliderItem" id={'SelectionTopSliderItem'} >
                            <img id={"SelectionTopSliderImg"} style={{ maxHeight: height - 105 }} src={item.MediaUrl} className="TopSliderImg" alt="" ></img>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div key={index}>
                        <div className="TopSliderItem" id={'SelectionTopSliderItem'} >
                            <img id={"SelectionTopSliderImg"} style={{ maxHeight: height - 105 }} src={item.MediaUrl} className="TopSliderImg" alt="" ></img>
                            <div className="Other"></div>
                        </div>
                    </div>
                )
            }
        })
        return (
            <div className="SliderTop" id={'SelectionTopSlider'}>
                <Slider {...SettingSlider}
                    adaptiveHeight={true}
                    centerMode={false}
                    focusOnSelect={true}
                    nextArrow={<SampleNextArrow />}
                    prevArrow={<SamplePrevArrow />}
                    initialSlide={this.props.currentSlide}
                    onInit={()=>{
                            this.setState({
                                currentSlide:this.props.currentSlide
                            })
                        }}
                    // beforeChange={(current, next) =>{setTimeout(() => this.setState(prevState => ({ ...prevState, currentSlide: next })), 500),
                    // 500}
                    // }
                 
                >
                {SliderItems}
                </Slider>
                { this.renderCounter() }
            </div>
        )

    }

    renderPoweredBy(render) {
        let { dictionary } = this.props;
        if (!render) {
            return (
                <div className={"PoweredBy"}>
                    {Parser(dictionary.slogan_powered_by)}
                </div>
            )
        }
    }

    renderDealScore(atHeader = false) {
        let { dictionary, dealScore = false } = this.props;
        if (dealScore) {
            let dealScoreRender = null;
            let rank = 0;
            if (this.props.analyseInfo !== undefined) {
                rank = this.props.analyseInfo.rank;
            }
            const currentLanguage = this.configurationService.getCurrentLanguagePrefix()
            const imgExcellent = require(`../../elements/images/excellent_deal_${currentLanguage}.png`).default;
            const imgGreat = require(`../../elements/images/great_deal_${currentLanguage}.png`).default;
            const imgGood = require(`../../elements/images/good_deal_${currentLanguage}.png`).default;



            if (rank === 2) {
                dealScoreRender = (
                    <div className={"DealScoreLine"} onClick={this.handleDealScore}>
                        <img className="DealScoreImg" src={imgGood} alt=""></img>
                        {this.renderPoweredBy(atHeader)}
                    </div>
                )
            }
            else if (rank === 3) {
                dealScoreRender = (
                    <div className={"DealScoreLine"} onClick={this.handleDealScore}>
                        <img className="DealScoreImg" src={imgGreat} alt=""></img>
                        {this.renderPoweredBy(atHeader)}
                    </div>
                )
            }
            else if (rank === 4) {
                dealScoreRender = (
                    <div className={"DealScoreLine"} onClick={this.handleDealScore}>
                        <img className="DealScoreImg" src={imgExcellent} alt=""></img>
                        {this.renderPoweredBy(atHeader)}
                    </div>
                )
            } else {
                dealScoreRender = (
                    null
                )
            }
            if (rank !== 0) {
                return (
                    <>
                        {!atHeader && <HOne title={dictionary.deal_score} addclass={"xsmall"} />}
                        {dealScoreRender}
                    </>
                );
            } else {
                return (
                    null
                );
            }
        } return null;

    }

    render() {
        let { isVisible, data, dictionary, width, height, agent } = this.props;
        if (data !== null && data.Media !== undefined) {
            let ParsedListingPrice = this.stringProcessingService.convertToFormat(data.ListPrice.toString());
            let ParsedSquareFeet = data.PropertyType !== "Land/Boat Docks" ? this.stringProcessingService.convertToFormat(data.LivingArea.toString()) : (
                this.stringProcessingService.convertToFormat(data.LotSizeSquareFeet.toString())
            )
            let flag = !isVisible || width < 300;
            const phone = require('../../elements/icons/phone-solid-white.png').default;
            const contactWA = require('../../elements/images/contactWA.png').default;
            if (!flag) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }

            return (
                <>
                    <AnimatePresence>
                        {(flag) ? (null) : (
                            <div
                                className="ModalCarusel"

                            >
                                <motion.div
                                    className="ListingPageModal"
                                    transition={{ duration: 0.3 }}
                                    initial={{ scale: 0, opacity: 1 }}
                                    exit={{ scale: 0, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                >
                                    <div className="ListingPageModalBody">
                                        <div id="SelectionListingHeader" className={"ListinPageHeaderLine"}>
                                            <div className="ListinPageStaticHeader">
                                                <div className="Title">
                                                    <div className={"VertLine"}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64.484" viewBox="0 0 2 64.484">
                                                            <defs>
                                                                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                                    <stop offset="0" stopColor="#a96903" />
                                                                    <stop offset="0.258" stopColor="#aa6a02" />
                                                                    <stop offset="0.352" stopColor="#b07102" />
                                                                    <stop offset="0.418" stopColor="#b97d02" />
                                                                    <stop offset="0.472" stopColor="#c78e01" />
                                                                    <stop offset="0.518" stopColor="#daa401" />
                                                                    <stop offset="0.557" stopColor="#f0be00" />
                                                                    <stop offset="0.579" stopColor="#ffd100" />
                                                                    <stop offset="1" stopColor="#a96903" />
                                                                </linearGradient>
                                                            </defs>
                                                            <g id="Сгруппировать_94" data-name="Сгруппировать 94" transform="translate(-272.933 -2822.173) rotate(90)">
                                                                <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="64.484" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div className="TitieAndDisc">
                                                        <h2>
                                                            {/* 18555 Collins Avenue, Unit 4803 */}
                                                            {`${data.StreetNumber} ${data.StreetName} ${data.UnitNumber}`}
                                                        </h2>
                                                        {`${data.City}, ${data.StateOrProvince} ${data.PostalCode}`}
                                                    </div>

                                                </div>
                                                <div className="MainInfo">
                                                    <span className="Separator FirstSep"></span>
                                                    <div className="Item">
                                                        {dictionary.bedrooms}:   <br /><span>   {data.BedroomsTotal != null && data.BedroomsTotal !== '' ? data.BedroomsTotal : (`N/A`)}   </span>
                                                    </div>
                                                    <span className="Separator"></span>
                                                    <div className="Item">
                                                        {dictionary.bathrooms}:  <br /> <span> {data.BathroomsTotalDecimal != null && data.BathroomsTotalDecimal !== '' ? data.BathroomsTotalDecimal : (`N/A`)} </span>
                                                    </div>
                                                    <span className="Separator"></span>
                                                    <div className="Item">
                                                        {dictionary.area_metric_full}:  <br /> <span>{ParsedSquareFeet != null && ParsedSquareFeet !== '' ? ParsedSquareFeet : (`N/A`)} </span>
                                                    </div>
                                                    <span className="Separator PrevSep"></span>
                                                    <div className="Item Last PriceInRow Price">
                                                        <div>
                                                            {dictionary.listing_price}:  <br /> <span>${ParsedListingPrice != null && ParsedListingPrice !== '' ? ParsedListingPrice : (`N/A`)} </span>
                                                        </div>
                                                    </div>

                                                    {(height <= 770) ? (
                                                        <>
                                                            <span className="Separator"></span>
                                                            <div className={"DealScoreAtTop"} >
                                                                {this.renderDealScore(true)}
                                                            </div>
                                                        </>
                                                    ) : (null)}

                                                    <span className="Separator LastSep"></span>
                                                </div>
                                                <div className="Item Last PriceInCol Price">
                                                    <div>
                                                        {dictionary.listing_price}:  <br /> <span>${ParsedListingPrice}</span>
                                                    </div>
                                                </div>
                                                <div className="Item" onClick={this.closeModal}>
                                                    <div className={"Close"}>
                                                        X
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="CloseLine" onClick={this.closeModal}>
                                            <div className={"Close"}>
                                                X
                                                    </div>
                                        </div>
                                        {/* media */}
                                        <div className="MediaMain" >
                                            <div className="MediaBlock" style={{ backgroundColor: 'black' }}>
                                                {this.renderMedia()}
                                            </div>
                                        </div>
                                        <div className='Buttons'>
                                            <a target="_blank" href={`https://wa.me/${agent.phone}`} className='ContactWAButton MenuItemAnchor'> <img className="ContactWA" src={contactWA} alt={'message'} /></a>
                                        </div>
                                    </div>
                                </motion.div>

                            </div>
                        )}
                    </AnimatePresence>
                </>
            )
        } else return null;

    };

}

export default ListingPageModal;