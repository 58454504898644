import React from 'react';
import GoogleMap from '../google-map';
import OffersGrid from '../offers-grid';
import Select from 'react-select';
import Helmet from 'react-helmet';

import ProjectCardsGrid from '../project-cards-grid'
import ServerApi from '../../services/server-api-service';
import FormCheckService from '../../services/form-check-service';
import StringProcessingService from '../../services/string-processing-service';
import { AnimatePresence, motion } from 'framer-motion';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    withRouter,
} from "react-router-dom";



import './construction-lobby-page-style.css';
const pageTransition = {
    in: {
        opacity: 1,
        transition: { duration: 0.5 }
    },
    out: {
        opacity: 0,
        transition: { duration: 0.5 }
    }
}
const data = require('./testInfo.json');
class ConstructionLobbyPage extends React.Component{
    stringProcessingService = new StringProcessingService();
    serverApi = new ServerApi();
    formCheckService = new FormCheckService();
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            // searchForm: {
            //     location: 0,
            //     condo: false,
            //     townhouse: false,
            //     single_family_home: false,
            // },
            searchInputForm: {
                location: 0,
            },
            totalItems: 0,
            itemOnPage: 7,
            currentPage: 1,
            totalPages: 0
        }
    }

    getProjectsList = () => {
        let id = '';

        let { location, saveState, form } = this.props;

        
        if (this.props.match.params.id) id = this.props.match.params.id
        this.serverApi.getProjectsList(id, form)
            .then((data) => {
                this.setState({
                    data: data.data
                })
                if(Array.isArray(data.data)&&data.data.length>0){
                    this.props.saveDataForMap(data.data);
                }else{
                    this.props.saveDataForMap([
                        {
                            latitude: 25.761681,
                            longitude: -80.191788,
                        }
                    ]);
                }
               
            })
            .catch((error) => {
                console.log(`ERROR: fetch getProjectsList ... -> Component: ConstructionLobbyPage ERROR:${error}`);
                // this.props.history.replace({pathname:`/page-not-found`});
            })
    }


    componentWillMount() {
        this.props.saveCurrentSelectionListing(null)
        window.addEventListener('getProjectsList', this.getProjectsList);
        // if (this.props.saveCurrentSelectionListing) this.props.saveCurrentSelectionListing(null);
        // setTimeout(() => {
        //     this.props.saveDataForMap(this.state.data);
        // }, 500) // time for API

        // if(location!=null&&location.state!=null&&location.state.searchForm){
        //     if(saveState){
        //         saveState(location.state.searchForm)
        //     }
        //     this.setState({
        //         ...this.state,
        //         searchForm:location.state.searchForm
        //     })
        // }

       
        this.getProjectsList();


        let initialData = {
            mapSide: "right",
            selectionHeader: false,
            showSelectionToolBar: true,
            selectionMapFloating: true,
            leftBlockWidth: 800,
            currentPage:'ConstructionLobbyPage'
        }
        this.props.saveState(initialData);
    }

    componentWillUnmount() {
        window.removeEventListener('getProjectsList', this.getProjectsList);
    }

    componentWillUpdate(){
        let page  = document.getElementsByClassName('Page')[0];
        if(page!=null){
           if(this.props.widthRouterMark!==page.clientWidth){
            this.props.saveState({
                widthRouterMark:page.clientWidth
            })
           }
        }
    }

    componentWillUnmount(){
        if(this.props.saveState){
            this.props.saveState({
                    Id:null
            })
        }
    }



    // handleButtonClick = (event) => {
    //     let name = event.target.name
    //     this.setState((prevState) => {
    //         return {
    //             searchForm: {
    //                 ...prevState.searchForm,
    //                 [name]: !prevState.searchForm[name]
    //             }
    //         }
    //     })
    // }

    render() {
        let locationIcon = require('../../elements/icons/s_location_offer.png').default;
        const smartDealImg = require('../../elements/images/sold_out_en.png').default;
        const tempMissoni = require('../../elements/images/missoni.png').default;


        let { dictionary, width, hoveredMarkerId, constructionLobbySearchForm } = this.props;
        let { data, searchForm } = this.state;
        if (data) {
            return (
             
                <motion.div 
                   variants={pageTransition}
                exit="out"
                animate="in"
                initial="out"
                className="Page">
                    <div className={"ConstructionLobby"}>
                        <Helmet>
                            <title>SMART LUXE</title>
                        </Helmet>
                        <ProjectCardsGrid
                            searchForm={searchForm}
                            hoveredMarkerId={hoveredMarkerId}
                            cards={data}
                            dictionary={dictionary}
                        />
                    </div>
                </motion.div>
            )
        } else return null;
    };

}

export default withRouter(ConstructionLobbyPage)