import React from 'react';

import Offer from '../offer';
import './offers-grid-style.css';
import HOne from "../h-one";
import { Link } from 'react-router-dom';
class OffersGrid extends React.Component {

    renderLastCard(index) {
        let { agentId, saveState, dictionary, smartDealEnable = true, disableLoupe = false,randomNumberForLastCardImage, lastCard = true, areaName, wizardOffers = false, width, neighborhoodImg } = this.props;
        if (lastCard) {
            return (
                <Offer
                    key={'LastCard'}
                    lastItem={true}
                    dictionary={dictionary}
                    disableLoupe={disableLoupe}
                    areaName={areaName}
                    neighborhoodImg={neighborhoodImg}
                    width={width}
                    smartDealEnable={smartDealEnable}
                    wizardOffers={wizardOffers}
                    agentId={agentId}
                    saveState={saveState}
                    randomNumberForLastCardImage={randomNumberForLastCardImage}
                />
            )
        }

    }

    renderBody() {
        let { agentId, saveState, titleLink = false, hoveredMarkerId, selection = false, data, selectionId, smartDealEnable = true, dictionary, disable, listingExpiredList = [], alignTitle = "center", width, limit, verticalList = false, dealScore, wizardOffers = false, blured = false } = this.props;
        if (limit === undefined && data) {
            limit = data.length
        }
        let offers;
        if (data !== undefined && data.length > 0) {
            //main offer
            offers = data.map((item, index) => {
                let expiredFlag = false;
                for (let i = 0; i < listingExpiredList.length; i++) {
                    if (listingExpiredList[i] === item.id) expiredFlag = true;
                }
                if (index < limit) {
                    if (!verticalList) {
                        if (index === data.length - 1) {
                            // console.log(item);
                            return (
                                <>
                                    <Offer
                                        hoveredMarkerId={hoveredMarkerId}
                                        key={item.id}
                                        data={item}
                                        dictionary={dictionary}
                                        dealScore={dealScore}
                                        similarOffers={data}
                                        wizardOffers={wizardOffers}
                                        width={width}
                                        selectionId={selectionId}
                                        smartDealEnable={smartDealEnable}
                                        expired={expiredFlag}
                                        selection={selection}
                                        agentId={agentId}
                                        saveState={saveState}
                                        verticalList={verticalList}
                                    />
                                    {this.renderLastCard(item.id)}
                                </>
                            );
                        } else {
                            return (
                                <Offer
                                    hoveredMarkerId={hoveredMarkerId}
                                    data={item}
                                    key={item.id}
                                    dealScore={dealScore}
                                    dictionary={dictionary}
                                    wizardOffers={wizardOffers}
                                    similarOffers={data}
                                    width={width}
                                    selectionId={selectionId}
                                    smartDealEnable={smartDealEnable}
                                    expired={expiredFlag}
                                    agentId={agentId}
                                    selection={selection}
                                    saveState={saveState}
                                    verticalList={verticalList}
                                />
                            );
                        }
                    } else {
                        //vertical offers
                        if (width > 1000) {
                            return (
                                <Offer
                                    hoveredMarkerId={hoveredMarkerId}
                                    data={item}
                                    dealScore={dealScore}
                                    key={item.id}
                                    dictionary={dictionary}
                                    similarOffers={data}
                                    agentId={agentId}
                                    verticalItem={true}
                                    wizardOffers={wizardOffers}
                                    width={width}
                                    selectionId={selectionId}
                                    smartDealEnable={smartDealEnable}
                                    expired={expiredFlag}
                                    selection={selection}
                                    saveState={saveState}
                                    verticalList={verticalList}
                                />
                            )
                        } else {

                            return (
                                <Offer
                                    hoveredMarkerId={hoveredMarkerId}
                                    data={item}
                                    key={item.id}
                                    similarOffers={data}
                                    dealScore={dealScore}
                                    dictionary={dictionary}
                                    agentId={agentId}
                                    width={width}
                                    selectionId={selectionId}
                                    smartDealEnable={smartDealEnable}
                                    wizardOffers={wizardOffers}
                                    expired={expiredFlag}
                                    selection={selection}
                                    saveState={saveState}
                                    verticalList={verticalList}
                                />
                            )
                        }


                    }
                }
            })


        }
        let blurClass = '';
        if (blured) {
            blurClass = 'Blured'
        }
        if (!verticalList) {
            if (data !== undefined && data.length > 0) {
                return (
                    <div className="GridMain" style={disable ? { pointerEvents: "none" } : {}}>
                        {titleLink ? (<Link to={'/find-my-deal'}><HOne color={'black'} key="GridTitle" title={this.props.title} align={alignTitle} /></Link>) :
                            (<HOne key="GridTitle" title={this.props.title} align={alignTitle} />)}

                        <br /><br />
                        <div className={`Grid ${blurClass}`}>
                            {offers}
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div className={width > 1000 ? `OffersGridVertical ${blurClass}` : `Grid ${blurClass}`} style={disable ? { pointerEvents: "none" } : {}}>
                    {offers}
                </div>

            )
        }
    }


    render() {


        // let alignText ='center'
        // if(this.props.alignTitle!==undefined){
        //     alignText=this.props.alignTitle
        // }

        return (
            <>
                {this.renderBody()}
            </>
        );
    }
}

export default OffersGrid;