import React from 'react';
import Slider from 'react-slick';
import UserForm from '../user-form';
import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';
import ThanksModal from "../thanks-modal";


import './project-slider-modal-style.css';
import HOne from '../h-one';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    const nextIcon = require('../../elements/icons/next_icon.png').default;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src={nextIcon} alt="Next" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    const prevIcon = require('../../elements/icons/prev_icon.png').default;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src={prevIcon} alt="Prev" />
        </div>
    );
}

class ProjectSliderModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            contactForm: {
                userFullName: '',
                userPhone: '',
                userEmail: '',
                userComment: `${props.dictionary.send_me_more_details_about_project} ${props.projectData.name}`,
                project:props.projectData.name
            },
            isVisibleThanksModal: false,
            currentSlide: props.currentSlide
        }
    }

    handleInput = (event) => {
        this.setState({
            contactForm: {
                ...this.state.contactForm,
                [event.target.name]: event.target.value
            }
        })
    }

    clearForm = () => {
        this.setState({
            ...this.state,
            contactForm: {
                userFullName: '',
                userPhone: '',
                userEmail: '',
                userMessage: ''
            },
        })
    }

    openThanksModal = () => {
        this.setState({ isVisibleThanksModal: true })
    }

    closeThanks = () => {
        this.setState({ isVisibleThanksModal: false })
    }
    componentWillMount() {

    }

    renderCounter(){
        let {currentSlide} = this.state;
        let {data} =  this.props;
        return (
            <div className="Counter">
                {currentSlide+1} of {data.length}
            </div>
        )
    }

    render() {
        let { data, closeModal, country, dictionary, width, currentSlide = 1, height, projectData, mobile = false } = this.props;
        let { contactForm } = this.state;
        const companyLogo = require('../../elements/images/missoni.png').default;

        if (mobile) {
            const settingsSlider = {
                slidesToShow: 1,//5
                nextArrow: <SampleNextArrow />,
                prevArrow: <SamplePrevArrow />,
                adaptiveHeight: true,
                lazyLoad:true



            };
            //mobile
            return (
                <Slider {...settingsSlider}
                    afterChange={(index) => { this.setState({ currentSlide: index }) }}
                    centerMode={false}
                    initialSlide={currentSlide}
                >
                    {data ? (
                        data.map((image, index) => {    
                            return (
                                <div key={index} className="ProjectSliderModalMobile">
                                    <div className="ProjectSliderModalMobileItem" >
                                        {/*<img style={{width:width-500}} src={`https://ngirealty.com/img/projects/${image.project_id}/big/${image.photo}`}  className="ProjectSliderModalImg" alt="" ></img>*/}
                                        <img src={`https://ngirealty.com/img/projects/${image.project_id}/big/${image.photo}`} className="ProjectSliderModalImg" alt="" ></img>
                                        {/* <img src={image.MediaURL} className="ProjectSliderModalImg" alt="" ></img> */}
                                    </div>
                                </div>
                            )
                        })
                    ) : (null)}
                </Slider>
            )
        } else {
            const settingsSlider = {
                slidesToShow: 1,//5
                nextArrow: <SampleNextArrow />,
                prevArrow: <SamplePrevArrow />,
                // adaptiveHeight: true,

            };
            //desctop verison
            return (
                <div className={'ProjectSliderModalParent'}>
                    <div className="ProjectSliderModalBlock">
                        <div id={'ProjectSliderModal'}>
                       
                            <Slider {...settingsSlider}
                                afterChange={(index) => { this.setState({ currentSlide: index }) }}
                                centerMode={false}
                                initialSlide={currentSlide}
                            >
                                {data ? (
                                    data.map((image, index) => {
                                        return (
                                            <div key={index} >
                                                <div className="ProjectSliderModalItem" >
                                                    {/*<img style={{width:width-500}} src={`https://ngirealty.com/img/projects/${image.project_id}/big/${image.photo}`}  className="ProjectSliderModalImg" alt="" ></img>*/}
                                                    <img src={`https://ngirealty.com/img/projects/${image.project_id}/big/${image.photo}`} className="ProjectSliderModalImg" alt="" ></img>
                                                    {/* <img src={image.MediaURL} className="ProjectSliderModalImg" alt="" ></img> */}
                                                </div>
                                                
                                            </div>
                                        )
                                    })
                                ) : (null)}
                            </Slider>
                        </div>
                        {this.renderCounter()}
                    </div>

                    <div className="ProjectSliderContact">
                        <HOne
                            line="none"
                            addclass={"LogoTitle"}
                            title={`${dictionary.request_about}`}
                        // title={`${dictionary.request_about} ${projectData.name}`}
                        />
                        <img src={`https://ngirealty.com/img/projects/${data[0].project_id}/${projectData.logo_invert}`} className={'Logo'} />


                        {projectData.web_urgency ?
                            <React.Fragment>
                                <div className={"FormText"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="138.247" height="2" viewBox="0 0 138.247 2">
                                        <defs>
                                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                <stop offset="0" stopColor="#a96903" />
                                                <stop offset="0.258" stopColor="#aa6a02" />
                                                <stop offset="0.352" stopColor="#b07102" />
                                                <stop offset="0.418" stopColor="#b97d02" />
                                                <stop offset="0.472" stopColor="#c78e01" />
                                                <stop offset="0.518" stopColor="#daa401" />
                                                <stop offset="0.557" stopColor="#f0be00" />
                                                <stop offset="0.579" stopColor="#ffd100" />
                                                <stop offset="1" stopColor="#a96903" />
                                            </linearGradient>
                                        </defs>
                                        <g id="Сгруппировать_94" data-name="Сгруппировать 94" transform="translate(-2822.173 274.933)">
                                            <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="138.247" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
                                        </g>
                                    </svg>
                                    <div className={"Urgency"}>{projectData.web_urgency}</div>
                                </div>
                            </React.Fragment>
                            : (null)
                        }

                        <UserForm
                            initialMessage={`${dictionary.send_me_more_details_about_project} ${projectData.name}`}
                            clearForm={this.clearForm}
                            contactForm={contactForm}
                            handleInput={this.handleInput}
                            width={width}
                            dictionary={dictionary}
                            secureInfo={true}
                            country={country}
                            openThanksModal={this.openThanksModal}
                        />
                    </div>
                    <ThanksModal
                        isVisible={this.state.isVisibleThanksModal}
                        title={dictionary.thank_you}
                        message={dictionary.thank_you_desc}
                        closeModal={this.closeThanks}
                        ModalBlurId={'ProjectSliderThankBlur'}
                    />
                </div>
            )
        }

    };

}

export default ProjectSliderModal;