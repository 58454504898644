import React from 'react';
import ReadMore from '../read-more';
import { Link } from 'react-router-dom';
import StringProcessingService from '../../services/string-processing-service';
import ConfigurationService from '../../services/configuration-service'
import './article-style.css';
import Parser from 'html-react-parser';

export default function Article(props) {
    const stringProcessingService = new StringProcessingService();
    const configurationService = new ConfigurationService();
    
    const iconDate = require('../../elements/images/icon_date.svg').default;
    let {data,dictionary,project} = props;
    
    if(data ==null){
        return null;
    }
    let options = { year: 'numeric', month: 'long', day: 'numeric' };

    let locale = 'en-US';
    switch(configurationService.getCurrentLanguagePrefix()){
        case 'pt':{
            locale="pt-PT";
            break;
        }
        case 'es':{
            locale="es-ES";
            break;
        }
        default:{
            break;
        }

    }
    let date = new Date(data.date).toLocaleDateString(locale, options);
    return (
        <Link to={project!= null?
        `/projects/${project.id}/${project.name}/articles/${data.id}/${stringProcessingService.StringFormatUrl(data.title)}` 
        :`/articles/${data.id}/${stringProcessingService.StringFormatUrl(data.title)}`}
         className={"ProjectInnerArticle"}>
            <div className={"ProjectInnerArticleImage"}><img src={data.photo} alt="" /></div>
            <div className={"ProjectInnerArticleText"}>
                <div className={"ArticleDate"}><img src={iconDate} alt="" /> {date}</div>
                <div className={"ArticleTextHdr"}>{data.title}</div>
                <div className={"ArticleText"}>{Parser(data.text_preview)}
                </div>
                <div className='ReadMore'>
                        {dictionary.read_more}
                </div>
            </div>
        </Link>
    )
}