import React from 'react';

import './project-company-page-style.css'
import HOne from "../h-one";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    withRouter,
    Redirect,
} from "react-router-dom";
import { default as vimg } from "../../elements/images/vimg.svg"; import { default as tmp_video } from "../../elements/tmp-images/tmp-video.png";
import ServerApi from '../../services/server-api-service';
import ConfigurationService from '../../services/configuration-service';
import GoogleMapStaticCustom from '../google-map-static-custom';
import UserForm from '../user-form';
import ReactPlayer from 'react-player';
import Parser from 'html-react-parser';
import Slider from 'react-slick';
import CustomList from '../custom-list';
import ProjectSliderModal from '../project-slider-modal';
import Modal from '../modal';
import Helmet from 'react-helmet';
import ActivityWatcher from '../../functions/user-activity/user-activity.js'
import CookiesManager from '../../services/cookies-manager';

import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';
import { default as tempMissoniLogo } from "../../elements/tmp-images/missoni-logo.png";
import { default as logo } from "../../elements/images/logo_positive_center.svg";
import { default as message } from "../../elements/icons/comment-dots-solid.png";
import { default as phone } from "../../elements/icons/phone-solid-white.png";
import { default as contactWA } from "../../elements/images/contactWA.png";
import { default as contactWAicon } from "../../elements/images/contactWA_icon.png";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    const nextIcon = require('../../elements/icons/next_icon.png').default;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src={nextIcon} alt="Next" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    const prevIcon = require('../../elements/icons/prev_icon.png').default;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src={prevIcon} alt="Prev" />
        </div>
    );
}


class ProjectCompanyPage extends React.Component {
    serverApi = new ServerApi()
    configurationService = new ConfigurationService();
    cookiesManager = new CookiesManager();
    date = new Date();
    watcher = null;
    constructor(props) {
        super(props)
        this.state = {
            debug: false,
            currentSlide: 1,
            isVisibleModalTop: false,
            isVisibleModalBottom: false,
            isVisibleModalBottomMobile: false,
            isVisibleMessage: false,
            isVisibleMessageScrollFlag: true,
            currentSlideBottomMobile: 0,
            currentSlideBottom: 0,
            isVisibleSchedule: false,
            scheduleForm: {
                date: `${this.date.getFullYear()}-0${this.date.getMonth() + 1}-0${this.date.getDate()}`
            },
            contactForm: {
                userFullName: '',
                userPhone: '',
                userEmail: '',
                userMessage: '',
                project: ''
            },
            currentJumbo: null,
            selectedCountry: null,
            topSliderImages: null,
            sliderData: [
                {
                    id: 1,
                    img: 'https://dvvjkgh94f2v6.cloudfront.net/523fa3e6/356623180/83dcefb7.jpeg'
                },
                {
                    id: 2,
                    img: 'https://dvvjkgh94f2v6.cloudfront.net/523fa3e6/356623180/1ad5be0d.jpeg'
                },
                {
                    id: 3,
                    img: 'https://dvvjkgh94f2v6.cloudfront.net/523fa3e6/356623180/6dd28e9b.jpeg'
                }
            ],

        }
        this.playSlider = this.playSlider.bind(this);
        this.pauseSlider = this.pauseSlider.bind(this);
    }
    componentDidMount() {
        this.watcher = new ActivityWatcher('openMessageModal');
        document.addEventListener('openMessageModal', this.openMessageModal)
        this.serverApi.getProjectInfo(this.props.match.params.id)
            .then((data) => {
                if (this.state.debug) console.log(`getProjectInfo data:`, data);


                let desktopImages = [];
                let mobileImages = [];
                if (data) {
                    for (let i = 0; i < data.gallery.length; i++) {
                        if (data.gallery[i].jumbo == 1) desktopImages.push(data.gallery[i]);
                        if (data.gallery[i].jumbo_mobile == 1) mobileImages.push(data.gallery[i]);
                    }
                }
                let projectName = '';
                    if(this.configurationService.getCurrentLanguagePrefix() != 'tr') projectName = data.name
                this.setState({
                    data: data,
                    currentProjectLogo: data.logo,
                    contactForm: {
                        ...this.state.contactForm,
                        userComment: `${this.props.dictionary.send_me_more_details_about_project} ${projectName}`,
                        project: data.name
                    },
                    desktopImages: desktopImages,
                    mobileImages: mobileImages
                })
                let initialData = {
                    currentProjectLogo: `https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo}`,
                    currentProjectLogoInvert: `https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo_invert}`,
                    allowedLanguages: data.ready_languages
                }
                this.props.saveState(initialData);
                // this.props.saveState('currentProjectLogo', `https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo}`);
                // this.props.saveState('currentProjectLogoInvert', `https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo_invert}`);
            })
            .catch((error) => {
                console.log(`ERROR: fetch getProjectInfo ... -> Component: ProjectCompanyPage ERROR:${error}`);
                this.props.history.replace({ pathname: `/page-not-found` });
            })
        let initialData = {
            selectionHeader: true,
            showSelectionToolBar: false,
            footerHideElem: true,
            currentPage: 'ProjectCompanyPage'
        }
        this.props.saveState(initialData);
        // this.props.saveState('selectionHeader', true);
        // this.props.saveState('showSelectionToolBar', false);
        // this.props.saveState('footerHideElem', true);
        if(document.location.search.includes('onstart=true')){
            this.setState({
                isVisibleMessage:true,
                onStartPopUp:true,
            })
        }
    }

    componentWillUnmount() {
        this.watcher.stopWatcher()
        document.removeEventListener('openMessageModal', this.openMessageModal);
        let initialData = {
            currentProjectLogo: null,
            footerHideElem: false,
            allowedLanguages: null,
        }
        this.props.saveState(initialData);
        // this.props.saveState('currentProjectLogo', null);
        // this.props.saveState('footerHideElem', false);
    }

    componentDidUpdate(prevProps) {
        let exitPop = this.cookiesManager.get('exit_pop');
        let windowRelativeBottom = document.documentElement.getBoundingClientRect().bottom;
        if ((windowRelativeBottom < ((document.documentElement.clientHeight + document.getElementsByClassName('Footer')[0].clientHeight) + (this.props.mobileVerison ? 1 : 0)) && prevProps.pageScroll < this.props.pageScroll)
            || (this.props.mouseEvent != null && this.props.mouseEvent.clientY < 5)) {
            // добавим больше данных
            if ((this.state.isVisibleMessage !== true && this.state.isVisibleMessageScrollFlag) && exitPop == null) {
                this.setState({
                    isVisibleMessage: true
                })
            }

        }
    }

    openMessageModal = () => {
        let exitPop = this.cookiesManager.get('exit_pop');
        if (!this.state.isVisibleMessage && this.state.isVisibleMessageScrollFlag && exitPop == null) {
            this.setState({
                isVisibleMessage: true
            })
        }
    }

    handleInput = (event) => {
        let { name, value } = event.target;
        this.setState((prevState) => {
            return {
                contactForm: {
                    ...prevState.contactForm,
                    [name]: value
                }
            }
        })
    }

    handleInputSchedule = (e) => {
        console.log('name:', e.target.name);
        console.log('value:', e.target.value);
        this.setState((prevState) => {
            return {
                scheduleForm: {
                    [e.target.name]: e.target.value
                }
            }
        })
    }

    renderDot() {
        return (
            <div className={'CustomDot'}></div>
        )
    }

    clearForm = () => {
        // this.setState({
        //     ...this.state,
        //     contactForm: {
        //         userFullName: '',
        //         userPhone: '',
        //         userEmail: '',
        //         userMessage: '',
        //         userComment: ''
        //     },
        // })
    }

    saveCurrentSelectedCountry = (value) => {
        this.setState({
            selectedCountry: value
        })
    }

    playSlider() {
        this.refTopSlider.slickPlay();
      }
    pauseSlider() {
        this.refTopSlider.slickPause();
    }


    closeModal = (isVisibleMessageScrollFlag = true) => {
        this.playSlider();
        this.setState({
            isVisibleModalTop: false,
            isVisibleModalBottom: false,
            isVisibleModalBottomMobile: false,
            isVisibleMessage: false,
            isVisibleSchedule: false,
            onStartPopUp:false,
            isVisibleMessageScrollFlag: isVisibleMessageScrollFlag
        })
    }

    handleScroll() {
        let keyDealFeatures = document.getElementsByClassName('ButtomForm')[0];
        keyDealFeatures.scrollIntoView({ block: "start", behavior: "smooth", alignToTop: true });
    }


    handleClickTopSlider = (id, imgId) => {
        let { data } = this.state;
        let newIndex = id;
        if (data.gallery != null && Array.isArray(data.gallery) && data.gallery.length > 0) {
            data.gallery.map((item, index) => {
                if (imgId === item.id) {
                    newIndex = index;
                }
            })
        }
        this.pauseSlider();
        this.setState((prevState) => {
            
            return {
                isVisibleModalTop: !prevState.isVisibleModalTop,
                currentSlideTop: newIndex
            }
        })
    }

    handleClickBottomSlider = (id, mobile = false) => {
        console.log(mobile)
        this.setState((prevState) => {
            if (mobile) {
                return {
                    isVisibleModalBottomMobile: !prevState.isVisibleModalBottomMobile,
                    currentSlideBottomMobile: id
                }
            } else {
                return {
                    isVisibleModalBottom: !prevState.isVisibleModalBottom,
                    currentSlideBottom: id
                }
            }

        })
    }



    renderShortTerm(){
        let { dictionary} = this.props;
        let {data} = this.state;
        if(data.short_rental == 'daily'){
          const termIcon = require(`../../elements/images/seal_short_term_${this.configurationService.getCurrentLanguagePrefix()}.png`).default;
          return(
            <div className="ShortTermIcon">
              <img src={termIcon} alt='Short Term Icon'/>
            </div>
          )
        }
        return null;
      }


    renderModal(isVisible, images, currentSlide, mobile = false, modalClass = '') {
        let exitPopUp = this.cookiesManager.get('exit_pop');
        let { dictionary, country, width } = this.props;
        let { data } = this.state;
        if (width > 300 && exitPopUp == null) {
            return (
                <>
                    <Modal
                        ModalBlurStyle={{ backgroundColor: "rgba(0,0,0,0.85)" }}
                        modalClass={modalClass}
                        ModalStyle={{ height: "100%", boxShadow: 'none', backgroundColor: 'unset', width: "100%", justifyContent: 'center', flexDirection: 'column' }}
                        closeModal={this.closeModal}
                        closeColor={'#fff'}
                        isVisible={isVisible}
                    >
                        <ProjectSliderModal
                            mobile={mobile}
                            projectData={data}
                            data={images}
                            width={width}
                            dictionary={dictionary}
                            closeModal={this.closeModal}
                            currentSlide={currentSlide}
                            country={country}
                        />

                    </Modal>
                </>
            )
        } else return null;
    }

    renderSliderTop() {
        let { sliderData, data, desktopImages, mobileImages, currentSlideTop, isVisibleModalTop } = this.state;
        let { dictionary, country, width } = this.props;
        let images = [];
        if (width > 550) images = desktopImages;
        else images = mobileImages;
        const termIcon = require(`../../elements/images/seal_short_term_${this.configurationService.getCurrentLanguagePrefix()}.png`).default;
        // if (data) {
        //     for (let i = 0; i < data.gallery.length; i++) {
        //         if (data.gallery[i].jumbo == 1 || data.gallery[i].jumbo_mobile == 1) images.push(data.gallery[i]);
        //     }
        // }
        // let images = [];
        // let desktopImages = [];
        // let mobileImages = [];
        // if (data) {
        //     for (let i = 0; i < data.gallery.length; i++) {
        //         if (data.gallery[i].jumbo == 1 || data.gallery[i].jumbo_mobile == 1) images.push(data.gallery[i]);
        //     }
        // }
        const settingsSlider = {
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            slidesToShow: 1,//5
            dots: true,
            arrows: false,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        arrows: true,
                        dots: false
                    }
                },
                // {
                //     breakpoint: 1200,
                //     settings: {
                //         slidesToShow: minSlidersValue, //5
                //         slidesToScroll: 1
                //     }
                // },
                // {
                //     breakpoint: 1000,
                //     settings: {
                //         slidesToShow: 0,//0
                //         slidesToScroll: 0
                //     }
                // }
            ],
        };
        return (
            <>
                <Slider 
                    {...settingsSlider}
                    ref={slider => (this.refTopSlider = slider)}
                    className={'ProjectCompanySlider'}
                    customPaging={this.renderDot}
                    onInit={() => {
                        if (images.length > 0) {
                            this.setState({
                                topSliderImages: images,
                                currentJumbo: images[0].jumbo_dark
                            })
                        } else if (data.gallery.length > 0) {
                            let arr = [];
                            for (let i = 0; i < 3; i++) {
                                arr.push(data.gallery[i])
                            }
                            this.setState({
                                topSliderImages: arr,
                                currentJumbo: images[0].jumbo_dark
                            })
                        }


                        // this.props.saveState('currentProjectLogo', `https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo}`);
                        // if(images[0].jumbo_dark===1) this.props.saveState('currentProjectLogo', `https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo}`);
                        // else this.props.saveState('cur  rentProjectLogo',`https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo_invert}`);
                    }}

                    afterChange={(index) => {
                        this.setState({
                            currentJumbo: this.state.topSliderImages[index].jumbo_dark
                        })

                        // if(this.state.topSliderImages[index].jumbo_dark===1) this.props.saveState('currentProjectLogo', `https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo}`);
                        // else this.props.saveState('currentProjectLogo',`https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo_invert}`);
                    }}
                >
                    {images.map((img, index) => {
                        let imgSrc = `https://ngirealty.com/img/projects/${data.id}/big/${img.photo}`;
                        let type = 'Default';
                        if (width > 550) {
                            //desctop
                            if (img.jumbo === 1) {
                                imgSrc = `https://ngirealty.com/img/projects/${data.id}/jumbotron/${img.jumbo_image}`;
                                type = 'Jumbotron';
                                // return (
                                //     <div className={'ItemBox'} key={`Jumbotron ${index}`}>
                                //         <img className={'Item'} src={`https://ngirealty.com/img/projects/${data.id}/jumbotron/${img.jumbo_image}`} alt={img.jumbo_image}></img>
                                //     </div>
                                // )
                            }
                            else if (img.jumbo_mobile === 1) {
                                return null;
                                imgSrc = `https://ngirealty.com/img/projects/${data.id}/mobile/${img.mobile_image}`;
                                type = 'Mobile';
                                // return (
                                //     <div className={'ItemBox'}>
                                //         <img className={'Item'} key={`Mobile ${index}`} src={`https://ngirealty.com/img/projects/${data.id}/mobile/${img.mobile_image}`} alt={img.mobile_image}></img>
                                //     </div>
                                // )
                            }
                        } else {
                            //mobile
                            if (img.jumbo_mobile === 1) {
                                imgSrc = `https://ngirealty.com/img/projects/${data.id}/mobile/${img.mobile_image}`;
                                type = 'Mobile';
                                // return (
                                //     <div className={'ItemBox'} key={`Mobile ${index}`}>
                                //         <img className={'Item'} src={`https://ngirealty.com/img/projects/${data.id}/mobile/${img.mobile_image}`} alt={img.mobile_image}></img>
                                //     </div>
                                // )
                            } else
                                if (img.jumbo === 1) {
                                    return null;
                                    imgSrc = `https://ngirealty.com/img/projects/${data.id}/jumbotron/${img.jumbo_image}`;
                                    type = 'Jumbotron';
                                    // return (
                                    //     <div className={'ItemBox'} key={`Jumbotron ${index}`}>
                                    //         <img className={'Item'} src={`https://ngirealty.com/img/projects/${data.id}/jumbotron/${img.jumbo_image}`} alt={img.jumbo_image}></img>
                                    //     </div>
                                    // )
                                }
                        }
                        return (
                            <div className={'ItemBox'} onClick={this.handleClickTopSlider.bind(this, parseInt(index), img.id)} key={`${type} ${index}`}>
                                <img className={'Item'} src={imgSrc} alt={img.photo}></img>
                            </div>
                        )
                    })}
                </Slider>
                    {this.renderShortTerm()}
            </>
        )
    }

    renderCounter(currentSlide) {
        let { data } = this.state;
        return (
            <div className="Counter">
                {currentSlide + 1} of {data.gallery.length}
            </div>
        )
    }

    renderSliderBottom(width) {
        let { sliderData, data, currentSlideBottom, isVisibleModalBottom } = this.state;
        let { dictionary } = this.props;

        const settingsSlider = {
            slidesToShow: 1,//5
            focusOnSelect: true,
            variableWidth: true,
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,

        };
        if (data.gallery && Array.isArray(data.gallery) && data.gallery.length > 0) {
            return (
                <>
                    {/* <HOne title={dictionary.gallery}></HOne> */}
                    <Slider {...settingsSlider}
                        ref={slider => (this.slider = slider)}
                        className="ProjectCompanyPageSlider"
                        initialSlide={currentSlideBottom}
                        afterChange={(index) => {
                            this.setState({ currentSlideBottom: index, currentSlideBottomMobile: index })
                        }
                        }
                    >
                        {data.gallery.map((image, index) => {
                            return (
                                <div key={index}>
                                    <div onClick={this.handleClickBottomSlider.bind(this, parseInt(index), false)} className="ProjectSliderItem" >
                                        <img src={`https://ngirealty.com/img/projects/${this.props.match.params.id}/thumb/${image.thumb}`} className="ProjectSliderButImg" alt="" ></img>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                    {this.renderCounter(currentSlideBottom)}
                </>
            )
        } else return null;

    }

    renderSliderBottomMobile(width) {
        let { sliderData, data, currentSlideBottom, isVisibleModalBottom } = this.state;

        const settingsSlider = {
            slidesToShow: 1,//5 
            focusOnSelect: false,
            variableWidth: false,
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,

        };

        return (

            <Slider {...settingsSlider}
                className="ProjectCompanyPageSliderMobile"
            >
                {data.gallery.map((image, index) => {
                    return (
                        <div key={index}>
                            <div onClick={this.handleClickBottomSlider.bind(this, parseInt(index), true)} className="ProjectSliderItemMobile"
                                style={{
                                    backgroundImage: `url("https://ngirealty.com/img/projects/${this.props.match.params.id}/thumb/${image.thumb}")`
                                }}
                            >
                                {/*<img src={`https://ngirealty.com/img/projects/${this.props.match.params.id}/thumb/${image.thumb}`} className="ProjectSliderButImg" alt="" ></img>*/}
                            </div>
                        </div>
                    )
                })}
            </Slider>
        )
    }

    parseNodeElem(data) {
        try {
            let ul = Parser(data);
            let children = ul.props.children;
            let arr = [];
            for (let i = 0; i < children.length - 1; i++) {
                if (children[i] !== "\n\t") {
                    arr.push(children[i].props.children)
                }
            }
            return arr;
        } catch (exception) {
            return false;
        }
    }

    renderKeyPoints() {
        let { data } = this.state;
        let { dictionary } = this.props;
        if (data !== undefined && data.key_points !== undefined) {
            let arr = this.parseNodeElem(data.key_points);
            if (arr === false && !Array.isArray(arr)) {
                return (<div>
                    FIX {dictionary.highlights} - MUST BE BULLETS
                </div>)
            } else {
                return (
                    arr.map((item, index) => {
                        return (
                            <div key={`Offer ${index}`}>
                                <div className={"Offer"}>
                                    <img src={vimg} alt="V" className={"Vimg"} />
                                    &nbsp;&nbsp;&nbsp;
                                    <div className={"TextBox"}>
                                        <div className={"Text"}>{item}</div>

                                    </div>

                                </div>

                                <div className={"Separator"}>
                                </div>
                            </div>

                        )
                    })
                )
            }
        }
    }

    renderButtons() {
        const phone = require('../../elements/icons/phone-solid-white.png').default;
        const message = require('../../elements/icons/comment-dots-solid.png').default;
        const contactWA = require('../../elements/images/contactWA.png').default;
        const contactWAicon = require('../../elements/images/contactWA_icon.png').default;
        let { contactForm, data, selectedCountry } = this.state;
        let { dictionary, width, country, pageScroll, height } = this.props;

        let res;
        if (this.configurationService.getCurrentLanguagePrefix() === 'en') {
            return (
                <>
                    {/* <a target="_blank" href={`tel:${data.main_agent.phone}`} className='Button'>Call <img className="Icon" src={phone} alt={'phone'} /></a> */}
                    {/* <div onClick={()=>{this.setState({isVisibleSchedule:true})}} className='Button'>{dictionary.schedule_presentation} </div> */}
                    <div onClick={() => { this.setState({ isVisibleMessage: true }) }} className='Button'>{dictionary.message} </div>
                    {/* <div onClick={()=>{this.setState({isVisibleMessage:true})}} className='Button'>{dictionary.message} <img className="Icon" src={message} alt={'message'} /></div> */}
                </>
            )

        } else {
            return (
                <>
                    {country === 'US' ? <a target="_blank" href={`https://wa.me/${data.main_agent.phone}?text=${contactForm.userComment}`} className='Button'><img className="IconWa" src={contactWAicon} alt={'phone'} /></a> : null}
                    <div onClick={() => { this.setState({ isVisibleMessage: true }) }} className='Button'>{dictionary.message} </div>
                    {/* <div onClick={()=>{this.setState({isVisibleMessage:true})}} className='Button'>{dictionary.message} <img className="Icon" src={message} alt={'message'} /></div> */}
                </>
            )
        }

    }



    render() {
        const tempMissoniLogo = require('../../elements/tmp-images/missoni-logo.png').default;
        const phone = require('../../elements/icons/phone-solid-white.png').default;
        const message = require('../../elements/icons/comment-dots-solid.png').default;
        const contactWA = require('../../elements/images/contactWA.png').default;
        const contactWAicon = require('../../elements/images/contactWA_icon.png').default;

        let { contactForm, sliderData, data, selectedCountry, currentJumbo, currentProjectLogo, isVisibleModalBottom, isVisibleModalTop,
            currentSlideTop, currentSlideBottom, topSliderImages, isVisibleModalBottomMobile,
            currentSlideBottomMobile, isVisibleMessage, isVisibleSchedule, scheduleForm ,onStartPopUp} = this.state;
        let { dictionary, width, country, pageScroll, mobileVerison, height } = this.props;

        let googleMapSize = {
            width: '500',
            height: '400',
            scale: 2
        }
        if (width < 1000) {
            googleMapSize.width = "1000";
            googleMapSize.height = '300';
        }

        // Calss for sticky buttons
        let sticky_buttons_class = 'Buttons StickyButtons';

        let ButForm = document.getElementsByClassName('ButtomForm')[0];
        let form_position_from_top = 1;
        if (ButForm) {
            let rect = ButForm.getBoundingClientRect();
            form_position_from_top = rect.top;
        }

        if (form_position_from_top - height + 200 < 0) {
            sticky_buttons_class = 'Buttons';
        }

        // if (pageScroll > 700 && form_position_from_top - height + 200 > 0) {
        //     sticky_buttons_class = 'Buttons StickyButtons';
        // }

        //

        let picture = null;
        if (data != null && data.gallery != null && Array.isArray(data.gallery)) {
            for (const photo of data.gallery) {
                if (photo.highlights_illustration) {
                    picture = photo.highlights_illustration_image;
                    break;
                }
            }
        }



        if (data) {
            // console.log('data.web_urgency', data.web_urgency);
            return (
                <div className={"Page CompanyProject"}>
                    <Helmet>
                        <title> {data.name} | Smart Luxe</title>
                    </Helmet>
                    <div className={"TopPart"}>
                        <div className={"TopSlider"} style={currentJumbo === 1 ? ({ color: 'white' }) : ({ color: 'black' })}>
                            <div className={"MiamiFl"}>{data.web_location ? (data.web_location) : (`Miami, FL`)}</div>

                            {width > 1000 ? (
                                <div className={"TopHeader"}>
                                    <HOne align={"left"} title={data.name} />
                                    {data.web_slogan && <div className={"Slogan"}>{data.web_slogan}</div>}
                                </div>
                            ) : (
                                <div className={"TopHeaderMobile"}>
                                    <div className={'Slogan'}>
                                        <HOne align={"center"} title={data.web_slogan} />
                                    </div>

                                    <div className={sticky_buttons_class}>

                                        {/* {this.configurationService.getCurrentLanguagePrefix() === 'en' ? (
                                            <>
                                                <a target="_blank" href={`tel:${data.main_agent.phone}`} className='Button'>Call <img className="Icon" src={phone} alt={'phone'} /></a>
                                                <div onClick={this.handleScroll} className='Button'>{dictionary.message} <img className="Icon" src={message} alt={'message'} /></div>
                                            </>

                                        ) : (
                                            <>
                                            <a target="_blank" href={`https://wa.me/${data.main_agent.phone}?text=${contactForm.userComment}`} className='Button'><img className="IconWa" src={contactWAicon} alt={'phone'} /></a>
                                            <div onClick={this.handleScroll} className='Button'>{dictionary.message} <img className="Icon" src={message} alt={'message'} /></div>
                                            </>
                                        )} */}

                                        {this.renderButtons()}
                                    </div>




                                </div>
                            )}



                            {this.renderSliderTop()}
                        </div>

                        {width > 1000 ? (
                            <div className={"Form"}>

                                <HOne addclass={"xsmall"} align={"center"} line={"none"} title={`${dictionary.request_about} `} />
                                <img src={data.logo === null ? null : `https://ngirealty.com/img/projects/${data.id}/${data.logo}`} className={"ProjectLogo"} alt=""></img>
                                {/* <img src={tempMissoniLogo} alt="" className={"ProjectLogo"} /> */}
                                {/*${data.name}*/}

                                {data.web_urgency ?
                                    <React.Fragment>
                                        <div className={"FormText"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="138.247" height="2" viewBox="0 0 138.247 2">
                                                <defs>
                                                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stopColor="#a96903" />
                                                        <stop offset="0.258" stopColor="#aa6a02" />
                                                        <stop offset="0.352" stopColor="#b07102" />
                                                        <stop offset="0.418" stopColor="#b97d02" />
                                                        <stop offset="0.472" stopColor="#c78e01" />
                                                        <stop offset="0.518" stopColor="#daa401" />
                                                        <stop offset="0.557" stopColor="#f0be00" />
                                                        <stop offset="0.579" stopColor="#ffd100" />
                                                        <stop offset="1" stopColor="#a96903" />
                                                    </linearGradient>
                                                </defs>
                                                <g id="Сгруппировать_94" data-name="Сгруппировать 94" transform="translate(-2822.173 274.933)">
                                                    <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="138.247" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
                                                </g>
                                            </svg>
                                            <div className={"Urgency"}>{data.web_urgency}</div>
                                        </div>
                                    </React.Fragment>
                                    : (null)
                                }

                                <UserForm
                                    watcher={this.watcher}
                                    thankModal={true}
                                    message={dictionary.thank_you_deal}
                                    initialMessage={`${dictionary.send_me_more_details_about_project} ${data.name}`}
                                    clearForm={this.clearForm}
                                    contactForm={contactForm}
                                    handleInput={this.handleInput}
                                    width={width}
                                    country={country}
                                    secureInfo={true}
                                    dictionary={dictionary}
                                    selectedCountry={selectedCountry}
                                    saveCurrentSelectedCountry={this.saveCurrentSelectedCountry}
                                    style={{ textAlign: 'center' }}
                                />
                            </div>
                        ) : (null)}
                    </div>

                    <div className={"WhatWeOfferLine"} >
                        <div className={"WhatWeOffer"} style={{ justifyContent: 'center' }}>
                            <div className={"WhatWeOfferLt"}>
                                <HOne align={"left"} title={dictionary.highlights} addclass="Title" />
                                <div className={"Offers"}>
                                    {this.renderKeyPoints()}
                                </div>
                            </div>

                            {data.video !== null &&
                                <div className={"WhatWeOfferRt"}>
                                    <ReactPlayer
                                        className='react-player fixed-bottom Video'
                                        url={data.video}
                                        controls={true}
                                        config={{
                                            youtube: {
                                                playerVars: {
                                                    modestbranding: 1,
                                                    color: 'red'
                                                }
                                            },
                                        }}
                                        onPlay={() => {
                                            this.watcher.stopWatcher();
                                        }}
                                        onPause={() => {
                                            this.watcher.resumeWatcher();
                                        }}
                                    />
                                </div>
                            }
                            {
                                data.video == null && picture != null ?
                                    <div className={"WhatWeOfferRt"}>
                                        <img src={`https://ngirealty.com/img/projects/${data.id}/features/${picture}`} id='PhotoHighLight' className='fixed-bottom' alt="Photo" />
                                    </div>
                                    : null
                            }

                        </div>
                    </div>

                    <div className={"FeaturesLine"}>
                        {data.web_building_features !== null ? (<>
                            <div className={"BuildingFeatures"}>
                                <HOne addclass={"xsmall"} align={"center"} title={dictionary.building_features} addclass={'Title'} />
                                <CustomList
                                    width={width}
                                    elem={data.web_building_features}
                                    listName={dictionary.building_features}
                                    dictionary={dictionary}
                                />
                                {/* {RenderList(data.web_building_features)} */}
                            </div>

                            <div className={'SeparatorBox'}>
                                <div className={'Separator'}>

                                </div>
                            </div>
                        </>) : (null)}

                        {data.web_amenities ? (
                            <div className={"BuildingFeatures"}>
                                <HOne addclass={"xsmall"} align={"center"} title={dictionary.amenities} addclass={'Title'} />
                                <CustomList
                                    width={width}
                                    elem={data.web_amenities}
                                    listName={dictionary.amenities}
                                    dictionary={dictionary}
                                />
                                {/* {RenderList(data.web_amenities)} */}
                            </div>
                        ) : (null)}


                        {(data.web_amenities !== null && data.web_building_features !== null && data.web_residences !== null) ? (
                            <>
                                <div className={'SeparatorBox'}>
                                    <div className={'Separator'}>

                                    </div>
                                </div>

                                <div className={"BuildingFeatures"}>
                                    <HOne addclass={"xsmall"} align={"center"} title={dictionary.residence_features} addclass={'Title'} />
                                    <CustomList
                                        width={width}
                                        elem={data.web_residences}
                                        listName={dictionary.residence_features}
                                        dictionary={dictionary}
                                    />
                                    {/* {RenderList(data.web_residences)} */}
                                </div>
                            </>
                        ) : null}

                    </div>
                    <HOne title={dictionary.gallery}></HOne>

                    <div className={"SliderAndMap"}>
                        <div className={"Slider"}>
                            {/* <ProjectSlider
                                data={data.gallery}
                                width={width}
                                dictionary={dictionary}
                            /> */}
                            {
                                width > 800 ?
                                    this.renderSliderBottom(width) : this.renderSliderBottomMobile(width)
                            }
                        </div>
                        <div className={"Map"}>
                            <GoogleMapStaticCustom
                                lat={data.latitude}
                                lng={data.longitude}
                                googleMapSize={googleMapSize}
                                zoom={12}
                            />
                        </div>
                    </div>

                    <div className={"ButtomForm"} id="ButtomForm">
                        <a href="#" name={"scrollForm"}></a>
                        <HOne addclass={"xsmall"} align={"center"} title={`${dictionary.request_about}`} line={"none"} />
                        <img src={data.logo === null ? null : `https://ngirealty.com/img/projects/${data.id}/${data.logo}`} className={"ProjectLogo"} alt=""></img>
                        {data.web_urgency ?
                            <React.Fragment>
                                <svg xmlns="http://www.w3.org/2000/svg" width="138.247" height="2" viewBox="0 0 138.247 2">
                                    <defs>
                                        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                            <stop offset="0" stopColor="#a96903" />
                                            <stop offset="0.258" stopColor="#aa6a02" />
                                            <stop offset="0.352" stopColor="#b07102" />
                                            <stop offset="0.418" stopColor="#b97d02" />
                                            <stop offset="0.472" stopColor="#c78e01" />
                                            <stop offset="0.518" stopColor="#daa401" />
                                            <stop offset="0.557" stopColor="#f0be00" />
                                            <stop offset="0.579" stopColor="#ffd100" />
                                            <stop offset="1" stopColor="#a96903" />
                                        </linearGradient>
                                    </defs>
                                    <g id="Сгруппировать_94" data-name="Сгруппировать 94" transform="translate(-2822.173 274.933)">
                                        <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="138.247" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
                                    </g>
                                </svg>
                                <div className={"FormText"}>
                                    {data.web_urgency}
                                </div>
                            </React.Fragment>
                            : (null)
                        }
                        <UserForm
                         watcher={this.watcher}
                            className={mobileVerison ? "MobileMessageModal" : ""}
                            thankModal={true}
                            clearForm={this.clearForm}
                            message={dictionary.thank_you_deal}
                            initialMessage={`${dictionary.send_me_more_details_about_project} ${data.name}`}
                            selectedCountry={selectedCountry}
                            saveCurrentSelectedCountry={this.saveCurrentSelectedCountry}
                            country={country}
                            inputBans={mobileVerison ? { comment: true, message: true } : {}}
                            topPartRow={true}
                            contactForm={contactForm}
                            handleInput={this.handleInput}
                            width={width}
                            secureInfo={true}
                            dictionary={dictionary}
                            style={{ textAlign: 'center', justifyContent: 'center' }}
                        />

                    </div>
                    {this.renderModal(isVisibleModalTop, data.gallery, currentSlideTop)}
                    {this.renderModal(isVisibleModalBottom, data.gallery, currentSlideBottom)}
                    {this.renderModal(isVisibleModalBottomMobile, data.gallery, currentSlideBottomMobile, true, 'ModalNoFelx')}

                    <Modal
                        ModalBlurStyle={{ backgroundColor: "rgba(0,0,0,0.85)" }}
                        closeModal={this.closeModal.bind(this, false)}
                        modalClass={'MessageModal'}
                        closeColor={'#000'}
                        speed={0.5}
                        ModalStyle={{ justifyContent: 'center', flexDirection: 'column', padding: "20px", alignItems: 'center' }}
                        isVisible={isVisibleMessage}
                        onStartPopUp={onStartPopUp}
                        >
                        <HOne addclass={"xsmall"} align={"center"} title={`${dictionary.request_about}`} line={"none"} />
                        <img src={data.logo === null ? null : `https://ngirealty.com/img/projects/${data.id}/${data.logo}`} className={"ProjectLogo"} alt=""></img>
                        {data.web_urgency ?
                            <React.Fragment>
                                <svg xmlns="http://www.w3.org/2000/svg" width="138.247" height="2" viewBox="0 0 138.247 2">
                                    <defs>
                                        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                            <stop offset="0" stopColor="#a96903" />
                                            <stop offset="0.258" stopColor="#aa6a02" />
                                            <stop offset="0.352" stopColor="#b07102" />
                                            <stop offset="0.418" stopColor="#b97d02" />
                                            <stop offset="0.472" stopColor="#c78e01" />
                                            <stop offset="0.518" stopColor="#daa401" />
                                            <stop offset="0.557" stopColor="#f0be00" />
                                            <stop offset="0.579" stopColor="#ffd100" />
                                            <stop offset="1" stopColor="#a96903" />
                                        </linearGradient>
                                    </defs>
                                    <br/>
                                    <g id="Сгруппировать_94" data-name="Сгруппировать 94" transform="translate(-2822.173 274.933)">
                                        <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="138.247" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
                                    </g>
                                </svg>
                                <br/>
                                <div className={"FormText"}>
                                    {data.web_urgency}
                                </div>
                            </React.Fragment>
                            : (null)
                        }
                        <UserForm
                            watcher={this.watcher}
                            className="MobileMessageModal"
                            thankModal={true}
                            inputBans={{ comment: true, message: true }}
                            clearForm={this.clearForm}
                            message={dictionary.thank_you_deal}
                            initialMessage={`${dictionary.send_me_more_details_about_project} ${data.name}`}
                            selectedCountry={selectedCountry}
                            saveCurrentSelectedCountry={this.saveCurrentSelectedCountry}
                            country={country}
                            topPartRow={true}
                            contactForm={contactForm}
                            handleInput={this.handleInput}
                            width={width}
                            secureInfo={true}
                            dictionary={dictionary}
                            style={{ textAlign: 'center', justifyContent: 'center' }}
                        />
                        <div className='CoverBlock'>
                            <img src={`https://ngirealty.com/img/projects/${data.id}/${data.cover_image}`} alt='cover' className='CoverImage' />
                        </div>
                    </Modal>
                    {/* <Modal
                        ModalBlurStyle={{ backgroundColor: "rgba(0,0,0,0.85)" }}
                        closeModal={this.closeModal}
                        modalClass={'MessageModal'}
                        closeColor={'#000'}
                        ModalStyle={{ justifyContent: 'center', flexDirection: 'column', padding: "20px", alignItems: 'center' }}
                        isVisible={isVisibleSchedule}>

                        <div className='Schedule'>
                            <div className='Title'>
                                <HOne addclass={"xsmall"} align={"center"} title={`${dictionary.schedule_presentation}`} line={"none"} />
                            </div>
                            <span className='Promt'>Select tour date and time</span>
                            <div className='DataPicker'>
                                <input className='Calendar' name='date' type={'date'} min={`${this.date.getFullYear()}-0${this.date.getMonth() + 1}-0${this.date.getDate()}`} value={scheduleForm.date} onChange={this.handleInputSchedule} />
                            </div>
                            <div className='TimePicker'>
                            </div>
                            <div className='ScheduleType'>
                                <div className='RadioBtn'>
                                    <input id="InPerson" type={'radio'} name='ScheduleType' value='InPerson' />
                                    <label for="InPerson">In Person</label>
                                </div>

                                <div className='RadioBtn'>
                                    <input id="ZoomCall" type={'radio'} name='ScheduleType' value='ZoomCall' />
                                    <label for="ZoomCall">Zoom Call</label>
                                </div>

                            </div>

                            <input className='Input' placeholder='Please enter toyr email' type={'text'} />
                            <br />
                            <button>start</button>
                        </div>
                    </Modal> */}
                </div>

            )
        }
        else return null;
    }
}

export default withRouter(ProjectCompanyPage)