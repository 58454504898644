import React from 'react';

import HOne from '../h-one';

import './page404-style.css'
class Page404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
    }
  }

  componentDidMount(){
    if(this.props.saveState) this.props.saveState({currentPage:'Page404'})
  }


  render() {
    let { dictionary } = this.props;
    
    const imgError = require('../../elements/images/404.png').default;
    return (
      <div className="NotFoundPage Page">
        <img className="ErrorImage" alt="404" src={imgError}/>
        <HOne title={dictionary.not_found}/>
      </div>
    )
  };

}

export default Page404;