import React from 'react';
import Parser from 'html-react-parser';

import UserCard from '../user-card';

import ServerApi from '../../services/server-api-service';
import './about-page-style.css';
import HOne from "../h-one";
import Helmet from 'react-helmet'

class AboutPage extends React.Component {
    serverApi = new ServerApi();
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
        }
    }

    componentWillMount() {
        if (this.props.saveState) this.props.saveState({ currentPage: 'AboutPage' })
        if (this.state.debug) {
            console.log("componentWillMount ... -> Component: AboutPage");
        }

        this.serverApi.getAboutPageInfo()
            .then((data) => {
                if (this.state.debug) {
                    console.log(`getAboutPageInfo data:`, data);
                }
                this.setState({
                    ...this.state,
                    pageData: data
                })
            })
            .catch((error) => {
                console.log(`ERROR: fetch getAboutPageInfo ... -> Component: AboutPage ERROR:${error}`);
            })
    }

    renderOurLeaderShip() {
        let { dictionary } = this.props;
        let { pageData } = this.state;
        if (pageData !== undefined && pageData.leadership.length > 0) {
            return (
                <div className="UserCardsGrid">
                    {pageData.leadership.map((item, index) => {
                        return (
                            <UserCard data={item} dictionary={dictionary} teamCard={false} />
                        )
                    })}
                </div>
            )
        }
        return null;
    }


    renderCustomerRealtions() {
        let { dictionary } = this.props;
        let { pageData } = this.state;
        if (pageData !== undefined && pageData.customer_relations.length > 0) {
            return (
                <div className="UserCardsGrid">
                    {pageData.customer_relations.map((item, index) => {
                        return (
                            <UserCard data={item} dictionary={dictionary} teamCard={false} />
                        )
                    })}
                </div>
            )
        }
        return null;
    }

    render() {
        const pageLogo = require('../../elements/images/about_page.png').default;
        const SmartImage = require('../../elements/images/smart_big.png').default;
        const iconFile = require('../../elements/icons/file.png').default;
        const iconLight = require('../../elements/icons/light.png').default;
        const iconPerson = require('../../elements/icons/person_a.png').default;
        //const LinesImage = require('../../elements/images/lines.png').default;

        let { dictionary } = this.props;

        let descript = Parser(dictionary.about_us_desc);

        return (

            <div className={"AboutUsPage MenuSpace"}>

                {
                    <Helmet>
                        <title> {dictionary.about} | Smart Luxe</title>
                        <meta name="description" content={descript[0] + descript[1].props.children + descript[2]} />
                    </Helmet>
                }

                <div className="PageLogo">
                    <img id="PageLogoImg" alt='Page logo' src={pageLogo}></img>
                    <div id="PageLogoTitle"><HOne title={dictionary.about_us} line={"large"} addclass={"lagresize"} /></div>
                </div>
                <div className={"AboutPageLine"}>
                    <div className="AboutPageTopBlock">
                        <div className={"AboutPageTopBlockBody"}>
                            <div>
                                <HOne title={dictionary.about_us_title} align={"left"} />
                                <div className={"AboutText"}>
                                    {Parser(dictionary.about_us_desc)}
                                </div>
                            </div>
                            <div>
                                <img className="AboutPageImgSmart" alt='smart' src={SmartImage} />
                            </div>
                        </div>
                    </div>

                    <div className="OurLeaderShipBlock">
                        <div>
                            <div className="OurLeaderShipBody">
                                <HOne title={dictionary.our_leadership} /><br /><br />
                                {/* <div style={{backgroundImage:`url(../../elements/images/lines.png)`}}>
                                            
                                        </div> */}
                                {this.renderOurLeaderShip()}

                            </div>
                        </div>
                    </div>



                    <div className="CustomerRelationsBlock">
                        <div>
                            <div className="CustomerRelationsBody">
                                <HOne title={dictionary.customer_relations} /><br /><br />
                                {/* <div style={{backgroundImage:`url(../../elements/images/lines.png)`}}>
                                            
                                        </div> */}
                                {this.renderCustomerRealtions()}
                            </div>
                        </div>
                    </div>
                </div>

                    <div className="OurValueBlock">
                        <HOne title={dictionary.our_values} />
                        <br /><br /><br />
                        <div className="ValuesBody">
                            <div>
                                <img className="Icon" src={iconFile} alt=""></img>
                                <h2>{dictionary.value_1.toUpperCase()}</h2>
                                <div className="Separator"></div>
                                <span className="Text">
                                    {dictionary.value_1_desc}
                                </span>
                            </div>
                            <div>
                                <img className="Icon" src={iconLight} alt=""></img>
                                <h2>{dictionary.value_2.toUpperCase()}</h2>
                                <div className="Separator"></div>
                                <span className="Text">
                                    {dictionary.value_2_desc}
                                </span>
                            </div>
                            <div>
                                <img className="Icon" src={iconPerson} alt=""></img>
                                <h2>{dictionary.value_3.toUpperCase()}</h2>
                                <div className="Separator"></div>
                                <span className="Text">
                                    {dictionary.value_3_desc}
                                </span>
                            </div>
                        </div>
                    </div>


            </div>
        )
    }
}

export default AboutPage;