import React, { useState } from 'react';
import HOne from '../h-one';

import './agent-card-style.css'
export default function AgentCard(props) {
    const contactWA = require('../../elements/images/contactWA.png').default;
    const contactWAlogo = require('../../elements/images/whatsapp_logo.png').default;
    let { agent, notes, title = '', date, disableNote = false ,width} = props;
    if (agent == null) {
        return null;
    }
    let lineWidth = '270'
    if(width<500){
        lineWidth = '140'
    }

    return (
        <div className={disableNote?(`ListingAgentCard NotesAndAgent`):(`NotesAndAgent`) } >

            {!disableNote && 
            <div className="Notes">
                <HOne addclass={'Title'} title={title} align="left" />
                {notes}
                <div className="Date">
                    Selection prepared: {date}
                </div>
            </div>}

            <div className={"Agent"}>
                <div className='AgentTop' >
                    <div className='Photo'>
                        <img className={"AgentImg"} src={`https://ngirealty.com/img/signature/${agent.profile_picture}`} />
                    </div>
                    <div className='Info'>
                        <div className='Name'>
                            {agent.name}<br />
                        </div>

                        {agent.title}<br />
                        Miami - Ft. Lauderdale
                        <HOne title='' line={'large'} lineWidth={lineWidth}></HOne>
                        <div className='TableBlock'>
                            <table>
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <td className='InfoIcon'>M</td>
                                        <td>{agent.phone}</td>
                                    </tr>
                                    <tr>
                                        <td className='InfoIcon'>E</td>
                                        <td><a href={`mailto:${agent.email}`}>{agent.email}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <div className='AgentBottom'>
                    <a target="_blank" className="ContactWABtn"  href={`https://wa.me/${agent.phone}`}><div>Contact Via</div> <img className="ContactWA" src={contactWAlogo} /></a>
                </div>

            </div>
        </div>

    )
}