import React from 'react';
import WistiaPlayer from 'react-player/wistia';

function SoldIcon(props){

    let {width} = props;
    let imgSrc = require('../../elements/icons/sold-medium.png').default;
    // if(width<=1000) imgSrc = require('../../elements/icons/sold-large.png').default;
    return(<img src={imgSrc} alt='Sold Icon'/>);
}

export default SoldIcon;