import React from 'react';
import {
    BrowserRouter as Router,
    withRouter,
    Link,
} from "react-router-dom";
import ConfigurationService from '../../services/configuration-service';
import './construction-lobby-menu-style.css'
class ConstructionLobbyMenu extends React.Component {
    configurationService = new ConfigurationService();
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            currentMenuItem: "Main Points",
            form:{
                neighbourhood:'',
                condo:false,
                singleFamilyHome:false,
                townhouse:false
            }
        }
    }

    // getAreas = () => {
    //     let { dictionary } = this.props;
    //     this.serverApi.getAreasForSearch()
    //         .then((data) => {
    //             if (this.state.debug) {
    //                 console.log(data);
    //             }
    //             let options = [{ value: 0, label: dictionary.any }];
    //             data.map((item, index) => {
    //                 options.push({ value: item.id, label: item.name })
    //             })

    //             this.setState({
    //                 areas: options
    //             })
    //         })
    //         .catch((error) => {
    //             console.log(`ERROR: fetch getAreas ... -> Component: search-Page ERROR:${error}`);
    //         })
    // }

    handleOnSelect = (event) =>{
        let value = event.target.value;
        if(event.target.nodeName==="INPUT"){
            value = event.target.checked;
        }

        if(this.props.handleInputForm){
            this.props.handleInputForm('constructionLobbySearchForm',event.target.name,value)
        }
        // window.dispatchEvent(new CustomEvent('getProjectsList',{}))
        // this.setState({
        //     form:{
        //         ...this.state.form,
        //         [event.target.name]:value
        //     }
        // })
        
    }

    handleOnBackButton = (event) =>{
        let {applicationHistoryService,location,history,form} = this.props;
        if(applicationHistoryService.getPrevPathname()!==null){
            history.push({
                pathname:applicationHistoryService.getPrevPathname(),
                state:{
                    searchForm:form,
                }
            })
        }else{
            history.push({
                pathname:'/projects',
                state:{
                    searchForm:form,
                }
            })
        }

        // this.props.history.goBack();
    }

     handleOnClickMenu(className, name) {
        let keyDealFeatures = document.getElementsByClassName(className)[0];
        console.log(this.props.location)
        keyDealFeatures.scrollIntoView({ block: "center", behavior: "smooth", alignToTop: true });
        if (this.state.currentMenuItem !== name) {
             this.setState((prevState) => {
                return {
                    currentMenuItem: name
                }
            })
            // window.dispatchEvent(new CustomEvent('getProjectsList',{}))
        }
   
    }


    renderMenuItem = (name, className, separator = false) => {
        let { currentMenuItem } = this.state;
        if (document.getElementsByClassName(className)[0] !== undefined) {
            return (
                <>
                    {separator && (
                        <div className={"MenuItemsSeparator"}></div>
                    )}
                    <div onClick={this.handleOnClickMenu.bind(this, className, name)} className={`MenuItem`}>{name}</div>
                </>
            )
        }
    }   

    renderLobbyLine() {
        let { currentSelectionListing, dictionary,neighboorhoods,form,handleInputForm } = this.props;
        let { currentMenuItem} = this.state;
        if (currentSelectionListing === undefined || currentSelectionListing === null) {
            return (
                <div className={"LobbyMenuLine"}>
                    <div className={"LobbyMenu"}>
                        <div className={"Neighbourhoods"}>
                            <div className="LobbyMenuHeader">
                                Neighbourhoods:
                            </div>
                            <select name={'neighbourhood'} value={form.neighbourhood} className="categorySelector" onChange={this.handleOnSelect}>
                                <option value="">Any</option>
                                {neighboorhoods&&(
                                    neighboorhoods.map((item,index)=>{
                                        return <option key={index+item.name} value={item.id}>{item.name}</option>
                                    })
                                )}
                                {/* <option value="Aventura">Aventura</option>
                                <option value="Bal Harbour">Bal Harbour</option>
                                <option value="Coconut Grove">Coconut Grove</option>
                                <option value="Coral Gables">Coral Gables</option>
                                <option value="Downtown Miami">Downtown Miami</option> */}
                            </select>
                        </div>
                        <div className={"ProjectType"}>
                            <div className="LobbyMenuHeader">
                                Project Type:
                            </div>
                            <div>
                                <label htmlFor="Condo">
                                    <input onChange={this.handleOnSelect} name={'condo'} checked={form.condo} value={form.condo} id="Condo" className={"LobbyMenuCheck"} type="checkbox"  />
                                    Condo
                                </label>
                                <label htmlFor="Homes">
                                    <input onChange={this.handleOnSelect} id="Homes" checked={form.singleFamilyHome}  className={"LobbyMenuCheck"} name={"singleFamilyHome"} type="checkbox" value={form.singleFamilyHome} />
                                    Single-family Homes
                                </label>
                            </div>
                            <label htmlFor="Townhouse">
                                <input onChange={this.handleOnSelect} id="Townhouse" checked={form.townhouse} className={"LobbyMenuCheck"} name={"townhouse"} type="checkbox" value={form.townhouse} />
                                Townhouse
                            </label>
                        </div>
                    </div>
                </div>
            )
        } else {
            const contactWA = require('../../elements/images/contactWA.png').default;
            return (
                <div className={"LobbyMenuLine"}>
                    <div className={"LobbyMenu"}>
                        {currentSelectionListing !== undefined && currentSelectionListing !== null ? (
                            <div
                                className="BackButton"
                                onClick={this.handleOnBackButton}
                                // onClick={()=>{this.props.history.goBack()}}
                            >&lt; {dictionary.back}</div>
                        ) : null}
                        <div className={"MenuItems"}>
                            {/* <div onClick={this.handleOnClickMenu.bind(this,"PropDescBlock","Main Points")} className={"selected MenuItem"}>Main Points</div>
                            <div className={"MenuItemsSeparator"}></div>
                            <div onClick={this.handleOnClickMenu.bind(this,"PropDescBlock","Main Points")} >Property Description</div>
                            <div className={"MenuItemsSeparator"}></div>
                            <div onClick={this.handleOnClickMenu.bind(this,"PropDescBlock","Main Points")} >Building Features</div>
                             <div className={"MenuItemsSeparator"}></div>
                            <a href="#">Amenities</a> 
                             <div className={"MenuItemsSeparator"}></div>
                            <a href="#">Residence Features</a> 
                            <div className={"MenuItemsSeparator"}></div>
                            <div onClick={this.handleOnClickMenu.bind(this,"PropDescBlock","Main Points")} >Resale Listings</div>
                             <div className={"MenuItemsSeparator"}></div>
                        <a href="#">Articles</a>  */}
                            {/* {this.renderMenuItem(dictionary.property_description,"ProjectInnerPropDesc",false)} */}
                            {this.renderMenuItem(dictionary.property_description, "ProjectInnerPropDesc", false)}
                            {this.renderMenuItem(dictionary.building_features, "ProjectInnerАnchorBuild", true)}
                            {this.renderMenuItem(dictionary.amenities, "ProjectInnerАnchorAmenties", true)}
                            {this.renderMenuItem(dictionary.residence_features, "ProjectInnerАnchorResidences", true)}
                            {this.renderMenuItem(dictionary.resales, "ProjectInnerАnchorResales", true)}
                            {this.renderMenuItem(dictionary.articles, "ProjectInnerАnchorArticles", true)}
                        </div>
                        {this.configurationService.getCurrentLanguagePrefix() === 'en'
                            ? (

                                <div onClick={this.handleOnClickMenu.bind(this, "ProjectInnerForm", dictionary.btn_request_details)} className={`${currentMenuItem == dictionary.btn_request_details ? "selected" : ''} MenuItemAnchor Button`}>{dictionary.btn_request_details}</div>
                            ) : (
                                <a target="_blank" href={`https://wa.me/${currentSelectionListing.phone}`} className='ContactWAButton MenuItemAnchor'> <img className="ContactWA" src={contactWA} alt={'message'} /></a>
                            )}
                    </div>
                </div>
            )

        }
    }


    render() {
        let { currentSelectionListing } = this.props;

        return false
        return (
            <>
                {this.renderLobbyLine()}
            </>
        )
    };
}

export default withRouter(ConstructionLobbyMenu);