import React from 'react';
import { withRouter } from "react-router-dom";

import FindMyDealBlock from '../find-my-deal-block';
import NeighborhoodsGrid from '../neighborhoods-grid';
import ServerApi from '../../services/server-api-service';
import Helmet from 'react-helmet';


import './neighborhoods-page-style.css';
import HOne from "../h-one";
class NeighborhoodsPage extends React.Component {
    serverApi = new ServerApi();
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
        }
    }


    componentWillMount() {
        if(this.props.saveState) this.props.saveState({currentPage:'NeighborhoodsPage'})
    }


    //INFO: save this component for rollback :)
    render() {


        let body = null;
        let { dictionary } = this.props;
        if (this.props.neighboorhoods !== undefined) {
          

            let data = this.props.neighboorhoods;
            let descriptionMeta = data.map((item,index)=>{
                if(index<data.length) return(` ${item.name}`)
                else return(`${item.name}`)
                
            })
            const pageLogo = require('../../elements/images/our_team_page.jpg').default;
            body = (
                <>
                   {
                        <Helmet>
                            <title> {dictionary.neighborhoods} | Smart Luxe</title>
                            <meta name="description" content={descriptionMeta} />
                        </Helmet>
                    }
                    <div className="PageLogo">
                        <img id="PageLogoImg" src={pageLogo} alt='logo'></img>
                        <div id="PageLogoTitle">
                            <HOne title={dictionary.neighborhoods} line={"large"} addclass={"lagresize"} />
                        </div>
                    </div>
                    <NeighborhoodsGrid
                        title ={dictionary.neighborhoods}
                        data={data}
                    />
                     <FindMyDealBlock dictionary={dictionary} />
                </>
            )

        }
        else {
            body = null;
        }
        return (
            <div className="NeighborhoodsPage MenuSpace">
                {body}
            </div>
        )
    }
}

export default withRouter(NeighborhoodsPage);