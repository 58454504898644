import React from 'react';
import {
    BrowserRouter as Router,
    Link,
    withRouter
} from "react-router-dom";

import Slider from 'react-slick';
import SoldIcon from '../sold-icon';


import Parser from "html-react-parser";
import StringProcessingService from '../../services/string-processing-service';
import ConfigurationService from '../../services/configuration-service';

import './offer-style.css';
import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';
import { toHaveDisplayValue } from '@testing-library/jest-dom/dist/matchers';

let imgSrc;
let imgLastCardClass = ''
// let linkParams = '';

class Offer extends React.Component {
    stringProcessingService = new StringProcessingService();
    configurationService = new ConfigurationService();
    constructor(props) {
        super(props);
        this.state={
            imgSrc:null,
            imgLastCardClass:''
        }
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        switch (this.props.randomNumberForLastCardImage) {
            case 1: {
                this.setState({
                    imgSrc:require('../../elements/images/Find_Deal_Generic_button_backgrounds_310x316px_AM_19-Aug-22_2.jpg').default,
                    imgLastCardClass:'LastCardTop'
                })
                // imgSrc = require('../../elements/images/Find_Deal_Generic_button_backgrounds_310x316px_AM_19-Aug-22_2.jpg').default;
                // imgLastCardClass = 'LastCardTop';
                // linkParams = '?btn_version=collage'
                break;
            }
            case 0: {
                this.setState({
                    imgSrc:require('../../elements/images/Find_Deal_Generic_button_backgrounds_310x316px_AM_19-Aug-22_1.jpg').default,
                    imgLastCardClass:'LastCardBottom'
                })
                // imgSrc = require('../../elements/images/Find_Deal_Generic_button_backgrounds_310x316px_AM_19-Aug-22_1.jpg').default;
                // imgLastCardClass = 'LastCardBottom';
                // linkParams = '?btn_version=map'
                break;
            }
            default: {
                break;
            }
        }
    }

    handleClick() {
        // let {data,selection,selectionId,similarOffers} = this.props;
        // if(selection) {
        //     this.props.history.push({
        //     pathname:`/selection/${selectionId}/listing/${data.id}`,
        //     state:{
        //         similarOffers:similarOffers
        //     }
        //     });
        // }
        //  else this.props.history.push(`/listing/${this.props.data.id}`);

    }

    getUrl() {
        let { data, selection, selectionId, similarOffers, agentId } = this.props;
        let pathName = '';
        if (agentId !== undefined && agentId !== null) pathName = `/selection/${selectionId}/${agentId}/listing/${data.id}`;
        else pathName = `/selection/${selectionId}/listing/${data.id}`
        if (selection) {
            return ({
                pathname: pathName,
                state: {
                    similarOffers: similarOffers
                }
            })
        }
        else return (`/listing/${this.props.data.id}`);
    }


    // diamondsRender = (rank) => {
    //     let result = [];
    //     const diamondEmpty = require('../../elements/icons/s_diamond_a.png').default;
    //     const diamondHalf = require('../../elements/icons/s_diamond_b.png').default;
    //     const diamondFull = require('../../elements/icons/s_diamond_c.png').default;


    //     if (rank >= 0 && rank < 5) {
    //         for (let i = 1; i <= 4; i++) {
    //             if (i <= rank) {
    //                 result.push(
    //                     <img key={i} className="OfferDiamond" src={diamondFull} alt=""></img>
    //                 )
    //             } else {
    //                 result.push(
    //                     <img key={i} className="OfferDiamond" src={diamondEmpty} alt=""></img>
    //                 )
    //             }
    //         }


    //     }
    //     return <React.Fragment>{result}</React.Fragment>;
    // }

    renderLoupe() {
        const iconSearch = require('../../elements/icons/search.png').default;

        if (!this.props.disableLoupe) {
            return (<img className="Icon SearchIcon" src={iconSearch} alt=""></img>)
        }
    }

    renderButton() {

        let { disableLoupe, dictionary, areaName = dictionary.find_my_deal, neighborhoodImg } = this.props;
        if (!disableLoupe) {
            return (<Link to="/find-my-deal" className="ButtonFindMyDeal Button">{dictionary.find_my_deal}</Link>)
        } else {
            return (
                <>
                    <div className={"FindIn"}>{dictionary.find_more_in} <br /> {areaName}</div>
                    <Link to={{
                        pathname: !areaName ? ("/find-my-deal") : (`/neighborhood/${this.props.match.params.id}/${this.props.match.params.name}/find-my-deal`),
                        state: { area: areaName, neighborhoodImg: neighborhoodImg }
                    }}
                        className="ButtonFindMyDeal Button">{dictionary.search}</Link>
                </>
            )
        }
    }


    renderDealScore() {
        let { data, selection = false, dealScore = true } = this.props;
        if (dealScore) {
            const currentLanguage = this.configurationService.getCurrentLanguagePrefix();
            const imgExcellent = require(`../../elements/images/excellent_deal_${currentLanguage}.png`).default;
            const imgGood = require(`../../elements/images/good_deal_${currentLanguage}.png`).default;
            const imgGreat = require(`../../elements/images/great_deal_${currentLanguage}.png`).default;
            switch (data.deal_rank) {
                case 2: {
                    return (<img className="DealScoreImg" src={imgGood} alt="" />)
                }
                case 3: {
                    return (<img className="DealScoreImg" src={imgGreat} alt="" />)
                }
                case 4: {
                    return (<img className="DealScoreImg" src={imgExcellent} alt="" />)
                }
                default: {
                    return null;
                }
            }
        }
    }

    renderExpired() {
        if (this.props.expired) {
            return (
                <div className="OfferExpired">
                    EXPIRED
                </div>
            )
        } else {
            return (null);
        }
    }

    renderImage() {
        let { data, countSilderImg = 4 } = this.props;
        let arrayOfImg = null;
        try {
            arrayOfImg = JSON.parse(data.Media);
        } catch (error) {

        }
        if (Array.isArray(arrayOfImg) && arrayOfImg.length > 0) {
            const settingsSlider = {
                slidesToShow: 1,
                // responsive: [
                //     {
                //         breakpoint: 1350,
                //         settings: {
                //             slidesToShow: 4,
                //             slidesToScroll: 1,
                //         }
                //     },
                //     {
                //         breakpoint: 1200,
                //         settings: {
                //             slidesToShow: 5,
                //             slidesToScroll: 1
                //         }
                //     },
                //     {
                //         breakpoint: 1000,
                //         settings: {
                //             slidesToShow: 0,
                //             slidesToScroll: 0
                //         }
                //     }
                // ],
            };
            return (
                <Slider {...settingsSlider}
                    centerMode={false}
                    focusOnSelect={true}
                    adaptiveHeight={true}
                >
                    {arrayOfImg.map((item, index) => {
                        if (index < countSilderImg) {
                            return (
                                <Link key={item} to={this.getUrl()}>
                                    <img className="OfferImg" src={item} alt="" />
                                </Link>
                            )
                        }
                    })}
                </Slider>
            )
        } else {
            if (data.image !== undefined && data.image !== null && data.image !== '') {
                return (
                    <Link to={this.getUrl()}>
                        <img key={data.id} className="OfferImg" src={data.image} alt="" />
                    </Link>
                )
            } else {
                return (
                    <Link to={this.getUrl()}>
                        <div className="OfferImg" />
                    </Link>
                )
            }

        }
    }

    renderTitle() {
        let { data } = this.props;
        const locationIcon = require('../../elements/icons/s_location.png').default;
        let title = '';
        if (data.custom_title) {
            return (
                <div className='OfferTitleBlock'>
                    <div className='Title'>
                        <img className="OfferLocationIcon" src={locationIcon} alt=''></img>
                        {data.custom_title}
                    </div>
                    <div className='OfferLocation' style={{ display: 'block' }}>

                        {data.city}, {data.state_or_province} {data.postal_code}
                    </div>
                </div>
            )
        } else {
            return (

                <div className='OfferTitleBlock'>
                    <div className='Title'>
                        <img className="OfferLocationIcon" src={locationIcon} alt=''></img>
                        {data.StreetNumber} {data.StreetDirPrefix} {data.StreetName} {data.UnitNumber!=null ? ('#'):('')}{data.UnitNumber}
                    </div>
                    <div className='OfferLocation' style={{ display: 'block' }}>

                        {data.city}, {data.state_or_province} {data.postal_code}
                    </div>
                </div>

            )
        }

    }
    // buttonInput(id){
    //     this.props.history.push(`/listing/id}`, {
    //       });
    // }

    render() {
        const currentLanguage = this.configurationService.getCurrentLanguagePrefix();
        const smartDealIcon = require(`../../elements/images/smart_deal_${currentLanguage}.png`).default;

        let {imgSrc,imgLastCardClass} = this.state;
        let { data,width,verticalList, saveState,selection, dictionary, hoveredMarkerId, lastItem = false, verticalItem = false, wizardOffers = false, smartDealEnable = true, expired = false } = this.props;
        let smartDeal = null;
        let bodyRender;
        if (data !== undefined && data.deal_rank === 4 && wizardOffers === false && smartDealEnable) {
            smartDeal = (
                <img className="SmartDealIcon" id={verticalItem ? "SmartDealIconVertical" : ""} src={smartDealIcon} alt="" />
            )
        }
        if (lastItem) {
            if(imgSrc){
                bodyRender = (
                    <>
                        <div className={`OfferLastItem OfferMain ${imgLastCardClass}`}>
                            {/*<h2 id="OfferFindMyDeal">{dictionary.find_more_deals}</h2>*/}
                            <img src={imgSrc} className="Logo" alt={dictionary.more_projects} />
                            {this.renderButton()}
                        </div>
                    </>
                )
            }else{
                bodyRender = (
                    <>
                        <div className={`OfferLastItem OfferMain `}>
                            <div>
                                    {this.renderLoupe()}
                            </div>
                            {this.renderButton()}
                            <div className={"PoweredBy"}>
                                {Parser(dictionary.slogan_powered_by)}
                            </div>
    
                        </div>
                    </>
                )
            }
            
        }
        if (data !== undefined) {
            let locationIcon = require('../../elements/icons/s_location_offer.png').default;
            let price = data.list_price.toString();
            if(selection && data.close_price != null && data.close_price > 0) price = data.close_price.toString();
            let parsedPrice = this.stringProcessingService.convertToFormat(price);
            if (verticalItem && !wizardOffers) {
                locationIcon = require('../../elements/icons/s_location.png').default;
                let image = data.Media !== undefined ? JSON.parse(data.Media)[0] : data.image;
                let classes = 'OfferMainVertical';
                if (expired) classes += ' Blured';
                if (hoveredMarkerId != null && hoveredMarkerId === data.id) classes += ' SelectedOffer'
                bodyRender = (
                    <div
                        className={classes}
                        style={expired ? { pointerEvents: "none" } : {}}
                        onMouseEnter={() => { if (saveState) saveState({ hoveredMarkerId: data.id }) }}
                        onMouseLeave={() => { if (saveState) saveState({ hoveredMarkerId: null }) }}
                    >
                        <Link to={this.getUrl()}>
                            <div className="LeftBlockOfferVertical">
                                {smartDeal}
                                {/* <div className="OfferTitle">
                                    <div className="OfferLocation">
                                        <img src={locationIcon} alt=''></img>
                                        {`${data.city}, ${data.state_or_province} ${data.postal_code}`}
                                    </div>
                                </div> */}
                                <img className="OfferImgVertical" src={image} alt="" />

                            </div>
                        </Link>
                        <div className="RightBlockOffer">
                            <div className="TitleBlock">
                                <div className="Title">
                                    {this.renderTitle()}
                                    {/* <div className="OfferLocation">
                                        
                                        <img className="OfferLocationIcon" src={locationIcon} alt=''></img>
                                        {data.custom_title ? ` ${data.custom_title} <br/> ${data.city}` : `${data.street_number} ${data.street_dir_prefix} ${data.street_name} # ${data.unit_number}, ${data.city}, ${data.state_or_province} ${data.postal_code}`}
                                    </div> */}
                                </div>
                                <Link to={this.getUrl()}>
                                    <button className="Button" >{dictionary.view_details}</button>
                                </Link>
                            </div>
                            <div className="InfoBlock">
                                <div className="MainInfo">
                                    <div className="InfoPart">
                                        <span>{data.living_area}</span>
                                        {dictionary.footage}
                                    </div>
                                    <div className="InfoPart">
                                        <span>{data.bedrooms_total}</span>
                                        {dictionary.bedrooms}
                                    </div>
                                    <div className="InfoPart">
                                        <span>{data.bathrooms_total_decimal}</span>
                                        {dictionary.bathrooms}
                                    </div>
                                </div>
                                <div id="PriceBlock">
                                    <span>
                                        ${parsedPrice}
                                    </span>
                                </div>
                            </div>
                            {/* <div className="Info">
                                <div>
                                    <span>{data.living_area}</span>
                                    <br />
                                    {dictionary.footage}
                                </div>
                                <div>
                                    <span>{data.bedrooms_total}</span>
                                    <br />
                                    {dictionary.bedrooms}
                                </div>
                                <div>
                                    <span>{data.bathrooms_total_decimal}</span>
                                    <br />
                                    {dictionary.bathrooms}
                                </div>
                            </div>
                            <div className="Price">
                                {dictionary.listing_price}:
                            <span>${parsedPrice}</span>
                            </div>
                            <button className="Button" onClick={this.handleClick}>{dictionary.view_deal_details}</button> */}
                        </div>

                    </div>
                )
            } else if (wizardOffers && verticalItem) {
                locationIcon = require('../../elements/icons/s_location.png').default;
                let images = JSON.parse(data.Media);
                bodyRender = (
                    <div className={expired ? ('OfferMainVertical Blured') : ('OfferMainVertical')} style={expired ? { pointerEvents: "none" } : {}} >
                        <Link to={this.getUrl()}>
                            <div className="LeftBlockOfferVertical">
                                {smartDeal}
                                {/* <div className="OfferTitle">
                                    <div className="OfferLocation">
                                        <img src={locationIcon} alt=''></img>
                                        {`${data.city}, ${data.state_or_province} ${data.postal_code}`}
                                    </div>
                                </div> */}
                                <img className="OfferImgVertical" src={images ? images[0] : null} alt="" />

                            </div>
                        </Link>
                        <div className="RightBlockOffer">
                            <div className="TitleBlock">
                                <div className="Title">
                                    <div className="OfferLocation">
                                        <img className="OfferLocationIcon" src={locationIcon} alt=''></img>
                                        {`${data.city}, ${data.state_or_province} ${data.postal_code}`}
                                    </div>
                                </div>

                            </div>
                            <div className="InfoBlock">
                                <div className="MainInfo">
                                    <div className="InfoPart">
                                        <span>{data.living_area}</span>
                                        {dictionary.footage}
                                    </div>
                                    <div className="InfoPart">
                                        <span>{data.bedrooms_total}</span>
                                        {dictionary.bedrooms}
                                    </div>
                                    <div className="InfoPart">
                                        <span>{data.bathrooms_total_decimal}</span>
                                        {dictionary.bathrooms}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className={"InfoBlockPrice"}>
                            ${parsedPrice}
                        </div>
                        {this.renderDealScore()}
                    </div>
                )
            } else if (wizardOffers) {
                bodyRender = (
                    <div className={expired ? ('OfferMain Blured') : ('OfferMain')} style={expired ? { pointerEvents: "none" } : {}}  >
                        <div className="TopBlockOffer">
                            {smartDeal}
                            <div className="OfferOverlay"></div>
                            <div className="OfferTitle">
                                {/* <span> Penthouse</span> */}
                                <div className="OfferLocation">
                                    <img src={locationIcon} alt=''></img>
                                    {`${data.city}, ${data.state_or_province} ${data.postal_code}`}
                                </div>
                            </div>

                            {this.renderImage()}
                            {/* <img className="OfferImg" src={`https://ngirealty.com/api/smart-luxe/v1/en/listing-image/${data.id}/thumb.jpg`} alt="" /> */}
                        </div>
                        <Link to={this.getUrl()} className="BottomBlockOfferWizard" >

                            {/* old version */}
                            {/* <div className="Info">
                                <div>
                                    <span>{data.living_area}</span>
                                    <br />
                                    {dictionary.footage}
                                </div>
                                <div>
                                    <span>{data.bedrooms_total}</span>
                                    <br />
                                    {dictionary.bedrooms}
                                </div>
                                <div>
                                    <span>{data.bathrooms_total_decimal}</span>
                                    <br />
                                    {dictionary.bathrooms}
                                </div>
                            </div>
                            <div className="Price">
                                {dictionary.listing_price}:
                            <span>${parsedPrice}</span>
                            </div>
                            <div className={"PoweredBy"}>
                            </div> */}

                            <div className={"ListingPrice"}>
                                {/*{dictionary.listing_price}:*/}
                                <span>${parsedPrice}</span>
                            </div>
                            <div className="BottomPart">
                                <div className={"Options"}>
                                    <span>{data.living_area}&nbsp;&nbsp;</span>
                                    {dictionary.footage}

                                    <br />
                                    <span>{data.bedrooms_total}&nbsp;&nbsp;</span>
                                    {dictionary.bedrooms}

                                    <br />
                                    <span>{data.bathrooms_total_decimal}&nbsp;&nbsp;</span>
                                    {dictionary.bathrooms}

                                </div>
                                <div>
                                    {this.renderDealScore()}
                                </div>
                            </div>


                            {/* <div className="Left">
                                <div className="InfoRowItem">
                                    {dictionary.bedrooms}
                                    <span>{data.bedrooms_total}</span>
                                </div>
                                <div className="InfoRowItem">
                                    {dictionary.bathrooms}
                                    <span>{data.bathrooms_total_decimal}</span>
                                </div>
                                <div className="InfoRowItem">
                                    {dictionary.footage}
                                    <span>{data.living_area}</span>
                                </div>
                                <div className="InfoRowItem">
                                   
                                </div>
                            </div>
                        
    
     */}
                            {/*<div className="Rate">*/}
                            {/*    {this.diamondsRender(data.deal_rank)}*/}
                            {/*</div>*/}
                            {/*<button className="Button" onClick={this.handleClick}>{dictionary.view_deal_details}</button>*/}
                        </Link>

                    </div>
                )
            }
            else {
                bodyRender = (
                    <div className={expired ? ('OfferMain Blured') : ('OfferMain')} style={expired ? { pointerEvents: "none" } : {}} >
                        <div className="TopBlockOffer">
                            {smartDeal}
                            <div className="OfferOverlay"></div>
                            <div className="OfferTitle">
                                {/* <span> Penthouse</span> */}
                                <div className="OfferLocation">
                                    <img src={locationIcon} alt=''></img>
                                    {`${data.city}, ${data.state_or_province} ${data.postal_code}`}
                                </div>
                            </div>

                            {this.renderImage()}
                            {/* <img className="OfferImg" src={`https://ngirealty.com/api/smart-luxe/v1/en/listing-image/${data.id}/thumb.jpg`} alt="" /> */}
                        </div>
                        <Link to={this.getUrl()} className="BottomBlockOffer">

                            {/* old version */}
                            {/* <div className="Info">
                                <div>
                                    <span>{data.living_area}</span>
                                    <br />
                                    {dictionary.footage}
                                </div>
                                <div>
                                    <span>{data.bedrooms_total}</span>
                                    <br />
                                    {dictionary.bedrooms}
                                </div>
                                <div>
                                    <span>{data.bathrooms_total_decimal}</span>
                                    <br />
                                    {dictionary.bathrooms}
                                </div>
                            </div>
                            <div className="Price">
                                {dictionary.listing_price}:
                            <span>${parsedPrice}</span>
                            </div>
                            <div className={"PoweredBy"}>
                            </div> */}



                            <div className="Left">
                                <div className="InfoRowItem">
                                    {dictionary.bedrooms}
                                    <span>{data.bedrooms_total}</span>
                                </div>
                                <div className="InfoRowItem">
                                    {dictionary.bathrooms}
                                    <span>{data.bathrooms_total_decimal}</span>
                                </div>
                                <div className="InfoRowItem">
                                    {dictionary.footage}
                                    <span>{data.living_area}</span>
                                </div>
                                <div className="InfoRowItem">
                                    {dictionary.listing_price}:
                                    <span>${parsedPrice}</span>
                                </div>
                            </div>
                            <div className="Right">
                                {/* deal score */}
                                {this.renderDealScore()}
                            </div>



                            {/*<div className="Rate">*/}
                            {/*    {this.diamondsRender(data.deal_rank)}*/}
                            {/*</div>*/}
                            {/*<button className="Button" onClick={this.handleClick}>{dictionary.view_deal_details}</button>*/}
                        </Link>

                    </div>
                )
            }
        }

        let additionalSoldID='';
        if(verticalList) additionalSoldID = 'OfferSoldIconVertical'

        return (

            <div className='OfferParent'>
                {selection && data.close_price != null && data.close_price > 0?(
                    <Link to={this.getUrl()} className={`OfferSoldIcon`} id ={`${additionalSoldID}`}>
                        <SoldIcon width={width} />
                    </Link>
                    ):(null)}
                {bodyRender}
                {this.renderExpired()}
            </div>
        )
    }
}

export default withRouter(Offer);